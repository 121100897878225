@import '~antd/dist/antd.less';

* {
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

// .App {
//   min-height: 100%;
//   display: grid;
//   grid-template: "main";
//   flex: 1;
//   position: relative;
//   overflow: hidden;
//   background-color: white;
// }

// .App > * {
//   grid-area: main; /* Transition: make sections overlap on same cell */
//   flex: 1 1 auto;
//   background-color: white;
//   position: relative;
//   height: 100vh; /* To be fixed */
// }
/* Transitions */
.next-exit-active {
  animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.next-enter-active {
  animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.prev-exit-active {
  // animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.prev-enter-active {
  animation: enterFromLeft 300ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.next1-exit-active {
  // position: absolute;
  z-index: 0;
  opacity: 1;
  width: 100%;
  // transition: translate3d(-25%, 0, 0);
  animation: leaveToLeft1 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

.next1-enter-active {
  position: absolute;
  z-index: 1;
  opacity: 1;
  width: 100%;
  // transition: translate3d(100%, 0, 0);
  animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

.prev1-exit-active {
  position: absolute;
  opacity: 1;
  z-index: 1;
  width: 100%;
  animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

.prev1-enter-active {
  position: absolute;
  z-index: 0;
  opacity: 1;
  width: 100%;
  transform: translate3d(0, 0, 0);
}

.transition-group-next1 .page-transition-exit-active {
  // position: absolute;
  z-index: 0;
  opacity: 1;
  width: 100%;
  // transition: translate3d(-25%, 0, 0);
  animation: leaveToLeft1 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

.transition-group-next1 .page-transition-enter-active {
  position: absolute;
  z-index: 1;
  opacity: 1;
  width: 100%;
  // transition: translate3d(100%, 0, 0);
  animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

.transition-group-prev1 .page-transition-exit-active {
  position: absolute;
  opacity: 1;
  z-index: 1;
  width: 100%;
  animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

.transition-group-prev1 .page-transition-enter-active {
  position: absolute;
  z-index: 0;
  opacity: 1;
  width: 100%;
  // transform: translate3d(0, 0, 0);
  animation: enterFromLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes leaveToLeft1 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-25%);
    filter: brightness(0.5);
  }
}


@keyframes leaveToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-25%);
    filter: brightness(0.5);
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-25%);
    filter: brightness(0.5);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

body {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Pretendard, 'Noto Sans Korean', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.sikdae-brand, .multiple-store .change {
  transition: all 0.2s;
}
.side-collapsed {
  
  .sikdae-brand {
    width: 40px;
    padding: 0 0 0 10px;
    overflow: hidden;
  }
  .sidebar-nav.nav .sidebar-menu-item {
    overflow-x: hidden;
    .menuName {
      display: none;
    }
  }
  .multiple-store {
    .storename {
      display: none;
    }
    .change {
      width: 45px;
    }
  }
  .sidebar-nav > .setting {
    width: 50px;
    background-color: rgb(173, 162, 162);
    background-image: url('assets/images/sidemenu/info_black_24dp.svg');
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    span {
      display: none;
    }
  }
} 


@primary-color: #4BB378;