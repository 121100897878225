.sikdae-brand {
	padding: 0px 40px;
	align-self: center;
}
.container-fluid > .navbar-header {
	display: flex;
	flex-direction: column;
}
.navbar-toggle.pull-left {
	align-self: flex-start;
}

.uploadInfo {
	display: inline-block;
	width: 100%;
}

.uploadInfoMsg {
	padding-left: 20px;
	line-height: 40px;
	font-weight: bold;
	float: left;
}
@primary-color: #4BB378;