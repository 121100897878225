.settlement-info-content{
	margin: 20px 20px 20px 20px;
}

.settlement-info-content > .date.active,
.settlement-info-content > .selectType.active,
.settlement-info-content > .salesStats-table.active,
.settlement-info-content > .graph.active,
.settlement-info-content > .content-area.active
{
	display: none;
}

.settlement-info-content .date {
	width: 100%;
	line-height: 60px;
	text-align: center;
	background-color: #46B476;
	height: 60px;
	border-left: 1px solid #3B9964;
	border-right: 1px solid #3B9964;
	border-top: 1px solid #3B9964;
}

.settlement-info-content .date .left {
	width: 20%;
	color: white;
	display: inline-block;
	/* 	padding-left: 20px; */
	height: 100%;
	line-height: 60px;
	text-align: center;
	font-size: 20px;
}
.settlement-info-content .date .right {
	display: inline-block;
	width: 80%;
	text-align: center;
}

.settlement-info-content .date .right .todate {
	color: white;
	font-size: 20px;
	width: 90%;
	height: 40px;
	display: inline-block;
}

.settlement-info-content .date .right .todate .string {
	width: 100%;
	line-height: 40px;
	height:40px;
	background-color: #3B9964;
	border: 1px solid #37925F;
}

.settlement-info-content .selectType {
	background-color: white;
	height: 50px;
	border-top: 1px solid #dcdcdc;
}

.settlement-info-content .selectType .active {
	color: #46b476;
	background-color: white;
	font-weight: bold;
	border-bottom: 3px solid #46B476 !important;
}

.settlement-info-content .selectType .type1,
.settlement-info-content .selectType .type2,
.settlement-info-content .selectType .type3,
.settlement-info-content .selectType .type4 {
	cursor: pointer;
	width: 25%;
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-size: 18px;
	display: inline-block;
}

.settlement-info-content .selectType .type1 {
	border-left: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
}
.settlement-info-content .selectType .type1 .active {
	border: 0px solid transparent;
}
.settlement-info-content .selectType .type2 .active {
	border: 0px solid transparent;
}

.settlement-info-content .selectType .type3 .active {
	border: 0px solid transparent;
}

.settlement-info-content .selectType .type4 .active {
	border: 0px solid transparent;
}

.settlement-info-content .selectType .type2,
.settlement-info-content .selectType .type3,
.settlement-info-content .selectType .type4 {
	border-left: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
}

.settlement-info-content .selectType .type4 {
	border-right: 1px solid #dcdcdc;
	width: 25%;
}

.settlement-info-content .graph {
	width: 48%;
	float: left;
	margin-top: 20px;
	margin-right: 2%;
	background-color: white;
	min-height: 200px;
	display: inline-block;
	border: 1px solid #dcdcdc;
}

.settlement-detail-content .box {
	background-color: white;
	border: 1px solid #dcdcdc;
	color: #333333;
}

.settlement-detail-content .box .tab {
	height: 60px;
	cursor: pointer;
	line-height: 60px;
	font-size: 18px;
	border-bottom: 1px solid #dcdcdc;
	padding-left: 20px;
	font-weight: bold;

}

.settlement-detail-content .box .arrow {
	width: 20px;
	height: 20px;
	margin-top: 20px;
	margin-right: 10px;
}
.settlement-detail-content .box .toggle {
	width: 62px;
	height: 31px;
	margin-top: 20px;
	margin-right: 10px;
}

.settlement-detail-content .box .left {
	float: left;
	width: 70%;
}
.settlement-detail-content .box .right {
	float: right;

}
.settlement-detail-content .box .voice {
	height: 80px;
	line-height: 30px;
	font-size: 18px;
	padding-left: 20px;
	border-bottom: 1px solid #dcdcdc;
	font-weight: bold;
	padding-top:10px;
}
.settlement-detail-content .box .voice .left {
	font-size :22px;
}
.settlement-detail-content .box .voice .notice {
	font-size: 14px;
	color: #999999;
	font-weight: 200;
}
.settlement-detail-content .box .desc {
	height: 55px;
	line-height: 20px;
	padding-top: 15px;
	padding-left:20px;
	font-size:18px;
	border-bottom: 1px solid #dcdcdc;

}

@media (max-width:767px) {

	.settlement-info-content {
		margin: 0px 0px 0px 0px;
	}

	.settlement-info-content .date {
		border-top: 0px solid transparent;
	}

	.settlement-info-content .graph {
		border: 0px solid transparent;
		width: 100%;
		margin-top: 0px;
	}

	.settlement-info-content .date .left {
		line-height: 60px;
		padding-left: 0px;
		font-size: 16px;
		width: 23%;
	}
	.settlement-info-content .date .right {
		width: 76%;
	}

	.settlement-info-content .date .right .todate .string {
		font-size: 15px;
	}

	.settlement-info-content .selectType,
	.settlement-info-content .selectType .type1,
	.settlement-info-content .selectType .type2,
	.settlement-info-content .selectType .type3,
	.settlement-info-content .selectType .type4 {
		line-height: 60px;
		height: 60px;
	}

	.settlement-detail-content .mobileHide {
		display: none;
	}
}

.settlement-detail-content .settlement-biz-wrap {
	height: auto;
	position: inherit;
	width: auto;
}
.settlement-detail-content .settlement-biz.screen {
	height: auto;
	position: inherit;
	width: auto;
}

.settlement-detail-content .settlement-biz.screen .itemTop {
	padding-top: 15px;
	padding-bottom: 15px;
}

.settlement-detail-content .settlement-biz.screen .item {
	padding-bottom: 15px;
}

@media screen and (min-width: 780px) {
	.settlement-detail-content .settlement-biz.screen {
		left: 0;
		margin-left: 0;
	}
}

/* 패스워드 변경 */
.settlement-biz-wrap {
	height: 100%;
	position: absolute;
	width: 100%;
	background-color: #fff;
}

.settlement-biz.screen {
	position: absolute;
	width: 100%;
	background-color: #fff;
}

.settlement-biz p {
	line-height: 1.53;
}

.settlement-biz .content {
	margin: 0 40px
}

.settlement-biz .content p {
	font-size: 19px;
	color: #222222;
	margin-left: 0;
	margin-top: 5px;
	font-weight: bold;
}

.settlement-biz .content p.re {
	margin-top: 32px;
}

.settlement-biz .content p.desc {
	font-size: 16px;
	color: #707371;
	font-weight: normal;
}

.settlement-biz .content input {
	display: inline-block;
	height: 50px;
	border-radius: 5px;
	border: 1px solid #dcdcdc;
	font-size: 16px;
}

.settlement-biz .btnArea {
	text-align: center;
	margin: 0 auto;
	padding-left: 30px;
	padding-top: 20px;
	padding-right: 30px;
	padding-bottom: 20px;
	display: flex;
}

.settlement-biz .btnArea .btn-block + .btn-block {
	margin-top: 0;
	margin-left: 4px;
}

.settlement-biz .update {
	height: 60px;
	color: white;
	font-weight: bold;
	font-size: 16px;
	border-radius: 5px;
	background-color: #32ba55;
}

.modal-body .settlement-biz .content {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
}
.modal-body .settlement-biz .content .flex-item {
	width: 400px;
	margin-bottom: 30px;
}
.modal-body .settlement-biz .content .flex-item:first-child {
	margin-right: 0;
}

@media (min-width:768px) {

	.settlement-biz .btnArea .btn-block + .btn-block {
		margin-top: 0;
		margin-left: 20px;
	}
	.modal-body .settlement-biz .content {
		flex-flow: row nowrap;
	}
	.modal-body .settlement-biz .content .flex-item:first-child {
		margin-right: 20px;
	}

	.pcHide{
		display: none;
	}
}

@media (min-width:800px) {
	.settlement-biz.screen {
		max-width: 50%;
		margin-left: -230px;
		left: 50%;
	}
}

.settlement-company-content  {
	padding-left: 20px;
	padding-right: 20px;
}

.settlement-company-content .realtime-table tbody tr {
	text-align: center;
	height: 50px;
	font-size: 16px;
	color: #333333;
}

.realtime-table, .settlement-company-table {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	border: 1px solid #dcdcdc;
	background-color: #ffffff;
}

.settlement-company-table tbody .infoTr {
	height: 50px;
	font-size:16px;
	text-align: center;
	border-bottom: 1px solid #dcdcdc;
}

.settlement-company-table tbody .infoTr td.downLink {
	text-decoration: underline;
	cursor: pointer;
}

.settlement-company-table tbody .seperate {
	background: #e4f6e7;
}

.settlement-company-table-pc-tr > th {
	font-weight: bold;
	height: 50px;
	font-size:16px;
	text-align: center;
	border-bottom: 2px solid #999999;
}

.settlement-company-table-tr > th {
	font-weight: bold;
	height: 30px;
	text-align: center;
	border-bottom: 1px solid #dcdcdc;
}

.settlement-company-table-pc-tr > td
{
	height: 50px;
	cursor: pointer;
	text-align: center;
	border-top: 1px solid #dcdcdc;
}

.settlement-company-content .vat {
	line-height: 22px;
	font-size: 16px;
	color: #19a333;
	text-align: center;
	margin: 0 auto;
	padding-top: 10px;
	padding-bottom: 32px;
}

.settlement-company-table tbody .detailTr {
	height: 50px;
	font-size:18px;
	text-align: center;
	background-color: #f1f0f0;
	border-bottom: 1px solid #dcdcdc;
}

.settlement-company-table tbody .detailTr td{
	padding: 5px !important;
	font-size: 15px;
}

.settlement-company-table tbody .detailTr span.downLink {
	text-decoration: underline;
	cursor: pointer;
}

.settlement-company-table .COMPLETE {
	color : #46b476;
	font-weight : bold;
}

.settlement-company-table .IMPOSSIBLE {
	color : #ff5b5b;
	font-weight : bold;
}

.settlement-company-table .detail-table {
	width: 100%;
}
.settlement-company-table .detail-table tr:not(:last-child) {
	border-bottom: 1px solid #d2d2d2;
}

.settlement-company-table .detail-table tr td:nth-child(even) {
	border-left: 1px solid #d2d2d2;
	text-align: left !important;
}

.settlement-company-table .detail-table td {
	padding : 10px;
	color: #525252;
}

.settlement-company-table .detail-table .btn-file {
	color: #ffffff;
	background: #333333;
	border: 1px solid #333333;
}

.settlement-company-table .detail-table .btn-file .excel-btn {
	width: 12px;
	margin-right: 5px;
}

.settlement-company-table .detail-table .impossible-memo {
	background-color: #fbf3f3;
}

.settlement-company-table .detail-table .impossible-memo td {
	background-color: #fbf3f3;
	color : #ff5b5b !important;
	font-weight: bold;
}

.settlement-biz-wrap .btn-file {
	background-color: #32ba55;
	height: 50px;
	padding: 10px;
	color: #ffffff
}

.settlement-biz-wrap .input-file .form-control {
	font-size: 14px;
}

.settlement-detail-content .info-box-warning {
	width: 100%;
	color: #f44;
	background-color: #fbf3f3;
	border: 1px solid #f99d9d;
	border-radius: 4px;
	padding: 6px 12px;
	font-size: 13px;
}

.settlement-detail-content .info-box {
	width: 100%;
	color: #19a333;
	background-color: #ecfbee;
	border: 1px solid #bee6c4;
	border-radius: 4px;
	padding: 6px 12px;
	font-size: 14px;
	margin-top: 20px;
}

.settlement-detail-content .bold {
	font-weight: bold;
}

.settlement-detail-content .list-box ol, .settlement-detail-content .list-box ul{
	padding-inline-start: 15px;
	margin-bottom: 0px;
}

.settlement-detail-content .info-box-warning .list-box{
	padding-left: 15px;
}

.settlement-detail-content .info-box-warning div:not(:first-child),
.settlement-detail-content .info-box div:not(:first-child) {
	padding-top: 12px;
}