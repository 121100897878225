::-webkit-scrollbar {
    display: none;
}

html {
    height: 100%;
    background: #f7f7f7;
}

body {
    height: 100%;
    background-color: white;
}

#wrapper {
    background-color: #f7f7f7;
    height: 100%;
}

.blockscroll {
    height: 100%;
}

.sikdae-brand {
    float: left;
    height: 60px;
    font-size: 26px;
    color: white;
    line-height: 60px;
    /*padding-left: 75px;*/
}

.sidebarNavbar {
    border-bottom: 1px solid #3B9964;
}

.notsupport, #storePay {
    display: none;
}

.subnav-default {
    margin-left: -1px;
    height: 60px;
}

.multiple-store {
    width: 290px;
    height: 120px;
    padding: 10px 20px;
    background-color: #46B476;
    color: white;
    border-bottom: 1px solid #46B476;
}
.multiple-store .nav-store-btn {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-content: center;

}
.multiple-store .store-name-wrapper {
    margin-top: auto;
    margin-bottom: auto;
}
.multiple-store .sidebar-thumbnail {
    width: 75px;
    display: flex;
    color: #6f6f6f;
    position: relative;
}
.multiple-store .sidebar-thumbnail img {
    width: 100%;
    margin: auto;
    cursor: pointer;
}
.multiple-store .sidebar-thumbnail .material-icons {
    margin: auto;
    padding: 10px;
    cursor: pointer;
}
.multiple-store .sidebar-thumbnail .material-icons.setting {
    display: none;
}
.multiple-store .sidebar-thumbnail:hover .material-icons.setting {
    display: block;
    position: absolute;
    right: 0px;
    bottom: 10px;
    padding: 0;
    margin: 0;
}

.multiple-store .store-name-wrapper > .storename {
    font-size: 20px;
    margin: 10px 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.multiple-store .store-name-wrapper .nav-store-btn > .number {
    font-size: 16px;
    color: white;
}


.multiple-store .store-name-wrapper .nav-store-btn > .change {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.sikdae-brand:hover,
.sikdae-brand:focus {
    color: white;
    text-decoration: none;
}

#sidebar {
    float: left;
    height: 100%;
}

#sidebar-wrapper {
    background-color: #fff;
    float: right;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    z-index: 1000;
    display: block;
    /* 	transition: all 0.4s ease 0s; */
    -ms-overflow-style: none;
}

.sidebar-active {
    background-color: #ECF7F1 !important;
}

#wrapper.active {
    display: block;
}

#wrapper.active #sidebar-wrapper {
    left: 0;
    /*   background-color: white; */
}

#spy {
    height: 90%;
    border-right: 1px solid #dcdcdc;
}

#page-content {
    height: 100%;
}

#page-content-wrapper {
    /* overflow-y: scroll; */
    /* 	float: right; */
    display: inline-block;
    width: 100%;
    background-color: #f7f7f7;
    /* 	height: 100%; */
}

.normalBackground,
.sikdae-content,
.storesupport,
.quickicons,
#mobileView {
    background-color: #f7f7f7;
}

#sikdae-content {
    height: 100%;
    margin-top: -60px;
    padding-top: 60px;
}

.subclass {
    height: 30px;
}

#sikdae-content .body {
    padding-left: 20px;
    padding-right: 20px;
}


.gnbicon {
    cursor: pointer;
    width: 27px;
}

.rightGnb {
    width: 27px;
}

.arrowicon {
    width: 20px;
}

.contacticon {
    width: 30px;
    margin-top: 10px;
}

.sidebar-nav {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
}

.realtime-History-mobile {
    height: 90px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    /* 	border-top: 1px solid #dcdcdc; */
    padding-bottom: 10px;
}

.realtime-History-mobile {
    height: 120px;
    /* 	border-bottom: 1px solid #dcdcdc; */
}

.realtime-History-mobile > .noti {
    height: 40px;
}

.realtime-History-mobile > .info {
    height: 50px;
}

.realtime-History-mobile > .noti > .issuccess {
    float: right;
    text-align: right;
    font-size: 22px;
    width: 40%;
    color: white !important;
}

.realtime-History-mobile > .noti > .iscancel {
    float: right;
    text-align: right;
    font-size: 22px;
    width: 40%;
    color: red !important;
}

.realtime-History-mobile > .noti > .history-menuName {
    font-size: 22px;
    color: #333333;
    width: 200px;
    float: left;
    width: 60%;
    height: 30px;
    font-weight: bold;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.realtime-History-mobile > .info > .comInfo {
    float: left;
    width: 40%;
    height: 60px;
    padding-top: 6px;
    line-height: 14px;

}

.realtime-History-mobile > .info > .comInfo > .companyName {
    font-size: 18px;
    height: 18px;
    color: #333333;
    line-height: 18px;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.realtime-History-mobile > .info > .comInfo > .time {
    color: #46B476;
    font-size: 18px;
}

.realtime-History-mobile > .info > .payInfo {
    float: right;
    font-size: 34px;
    height: 60px;
    width: 60%;
    text-align: right;
    line-height: 60px;
    font-weight: bold;
}

.realtime-History-mobile > .info > .payInfo > .won {
    font-size: 22px;
    font-weight: normal;
}

.sidebar-nav li {
    text-indent: 30px;
    border-bottom: 1px solid #eeeeee;
}

.sidebar-nav li div:hover,
.sidebar-nav li div:focus {
    background-color: #DAF0E4;
}

#spy .disabledMenus {
    background-color: #e2e2e2 !important;
}

.sidebar-nav li div {
    color: #999999;
    height: 70px;
    line-height: 70px;
    display: block;
    background-color: white;
    text-decoration: none;
}

.sidebar-nav li div img {
    width: 22px;
    margin-top: -4px;
}

.menuName {
    padding-left: 20px;
    font-size: 18px;
    color: #333333;
}

.sidebar-nav li div span:before {
    position: absolute;
    color: #41484c;
    text-align: center;
    line-height: 18px;
}


.sidebar-nav > .contactus {
    width: 230px;
    height: 70px;
    border-radius: 8px;
    background-color: #eeeeee;
    border: solid 2px #dcdcdc;
    margin: 20px auto;
    font-size: 18px;
    color: #999999;
}

.sidebar-nav > .contactus > .denhwa {
    height: 90px;
    padding-top: 10px;
}

.sidebar-nav > .contactus > .denhwa a.call,
.sidebar-nav > .contactus > .denhwa a.call:hover,
.sidebar-nav > .contactus > .denhwa a.call:link,
.sidebar-nav > .contactus > .denhwa a.call:active,
.sidebar-nav > .contactus > .denhwa a.call:focus {
    color: #999999;
}

.sidebar-nav > .contactus > .message {
    padding-top: 7px;
}

.sidebar-nav > .contactus > .hr {
    display: none;
    height: 2px;
    width: 200px;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #dcdcdc;
}

.sidebar-nav > .contactus > .denhwa > .image, .sidebar-nav > .contactus > .message > .image {
    width: 60px;
    float: left;
    padding-top: 0px;
    margin-left: 10px;
    text-align: center;
}

.sidebar-nav > .contactus > .denhwa > .info,
.sidebar-nav > .contactus > .message > .info {
    width: 150px;
    display: block;
    font-size: 18px;
    text-align: left;
    float: right;
}

.sidebar-nav > .contactus > .message > .image,
.sidebar-nav > .contactus > .message > .info {
    display: none;
}

#store-selector .modal-body {
    margin-bottom: 20px;
    text-align: center;
}

#store-selector .modal-body .infoBox {
    width: 80%;
    margin-top: 25px;
    margin-bottom: 15px;
    display: inline-block;
}

#store-selector .modal-body .infoBox .infoText b {
    color: black;
}

#store-selector .modal-body .infoBox .infoText {
    color: #999999;
    font-size: 18px;
    text-align: left;
}

#store-selector .storeName {
    width: 80%;
    cursor: pointer;
    display: inline-block;
    height: 50px;
    border-radius: 10px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #333333;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid #dcdcdc;
    margin-bottom: 5px;
}

#store-selector .subClass {
    width: 75% !important;
    margin-left: 5% !important;
}

#store-selector .storeName:hover {
    background-color: #dcdcdc;
    color: white;
}

.store-select-content .selected,
#store-selector .selected {
    background-color: #333333 !important;
    color: white;

}

.store-select-content .storeName {
    width: 100%;
    height: 70px;
    cursor: pointer;
    font-size: 24px;
    background-color: white;
    text-align: center;
    line-height: 70px;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    margin-bottom: 5px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-nav > .setting {
    margin: 20px auto;
    line-height: 40px;
    width: 230px;
    height: 40px;
    border-radius: 8px;
    background-color: #333333;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

}

.cancelNum {
    width: 230px;
    height: 40px;
    background-color: #ffffff;
    border: solid 1px #fa503c;
    margin: 30px 20px 20px 30px;

    font-size: 18px;
    color: #fa503c;
    text-align: center;
    padding-top: 7px;
}

.mainGuideBtn.cancelPw {
    border-color: #fa503c !important;
    color: #fa503c !important;
}

/*
.sidebar-nav li a:hover,
.sidebar-nav li.active {
  color: #fff;
  background: rgba(255,255,255,0.2);
  text-decoration: none;
}
*/

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    line-height: 60px;
    font-size: 18px;
}

.sidebar-nav > .sidebar-brand a {
    color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}


.content-header {
    height: 65px;
    line-height: 65px;
}

.content-header h1 {
    margin: 0;
    margin-left: 20px;
    line-height: 65px;
    display: inline-block;
}

#menu-toggle {
    text-decoration: none;
}

.btn-menu {
    color: #000;
}

.inset {
    padding: 20px;
}

#mask {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: 9000;
    margin-left: 290px;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}

.toptitle {
    height: 60px;
    font-size: 22px;
    /* line-height: 60px; */
    color: white;
}


.index {
    height: 176px;
    /* margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px; */

}

.index .quickicons .qbtn .blockleft {
    float: left;
}

.index .quickicons .qbtn .blockright {
    float: right;
}

.index .top .today {
    height: 34px;
    color: white;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    border-top: 1px solid #46B476;
    border-left: 1px solid #46B476;
    border-right: 1px solid #46B476;
    border-bottom: 1px solid #3B9964;
    background-color: #46B476;

}

.index .top .monthSell {
    height: 70px;
    background-color: #46B476;
    border-left: 1px solid #46B476;
    border-right: 1px solid #46B476;
    border-bottom: 1px solid #46B476;

}

.index .top .graph {
    min-height: 150px;
    border-right: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    background-color: white;

}

.index .top .monthSell .left-mobile {
    display: none;
}

.index .top .monthSell .left-pc {
    display: block;
    float: left;
    line-height: 70px;
    color: white;
    font-size: 18px;
    padding-left: 20px;
    text-align: left;
    height: 70px;
}

.index .top .monthSell .right {
    float: right;
    line-height: 70px;
    color: white;
    font-size: 34px;
    text-align: right;
    height: 70px;
    font-weight: bold;
    padding-right: 20px;
}

.index .top .monthSell .right .won, .index .top .todaySell .right .won {
    font-size: 22px;
}

.index .top .todaySell {
    height: 70px;
    background-color: white;
    border-right: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.index .top .todaySell .left {
    float: left;
    line-height: 70px;
    color: #8f8f8f;
    height: 70px;
    font-size: 18px;
    text-align: left;
    padding-left: 20px;
}

.index .top .todaySell .right {
    float: right;
    font-weight: bold;
    text-align: right;
    height: 70px;
    line-height: 70px;
    color: black;
    font-size: 34px;
    padding-right: 20px;
}


.index .quickicons {
    width: 100%;
    padding-top: 10px;
    margin: 0 auto;
    margin-left: 0px;
    text-align: center;
}

.index .block1, .index .block2 {
    width: 48.5%;
    display: inline-block;
}

.index .block1 {
    margin-right: 3%;
}

.index .quickicons .qbtn {
    display: inline-block;
    width: 50%;
    margin-top: 10px;
}

.index .quickicons .qbtn .last {
    width: 100% !important;
}

.index .quickicons .qbtn .box {
    cursor: pointer;
    width: 95%;
    height: 130px;
    background-color: #ffffff;
    /* 	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3); */
    border: 1px solid #dcdcdc;
    font-size: 20px;
    color: #333333;
    padding-top: 30px;

}

.index .quickicons .qbtn .box .name {
    margin-top: 10px;
    font-size: 20px;
}

.index .quickicons .qbtn .box img {
    width: 40px;
}

.index .storesupport {
    width: 100%;

    padding-top: 20px;
    padding-bottom: 20px;
}

.index .storepay, .index .mainGuideBtn {
    height: 70px;
    width: 290px;
    margin: 20px auto;
    line-height: 70px;
    border: 1px solid #dcdcdc;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    background-color: white;
    color: #333333;
}

.index .cancelbox {
    height: 70px;
    line-height: 70px;
    border: 2px solid #fa503c;
    background-color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 200;
    width: 290px;
    margin: 20px auto;
    color: #fa503c;
}

/* realtime-timeline */

.mobile-hour-line {
    background-color: #eeeeee;
    /* 	border: 1px solid #dcdcdc !important; */
    text-align: center;
    font-size: 12px;
    height: 34px;
    line-height: 34px;

}


.realtime-content, .salesstats-content, .calc-content, .setting-content, .MasterSalesstats-content, .reserveStatus-content {
    /* margin: 20px 20px 20px 20px; */
}

.realtime-table, .excel-table {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #dcdcdc;
    background-color: #ffffff;
}
.realtime-table {
    margin-top: 20px;
}

.pc-tr-cancel {
    background-image: url("../images/real-time-report/delete1px_w.png");
}

.mobile-tr-cancel {
    background-image: url("../images/real-time-report/delete1px_m.png");
}

.realtime-table-pc-tr > td,
.excel-table-pc-tr > td {
    height: 50px;
    cursor: pointer;
    text-align: center;
    border-top: 1px solid #dcdcdc;

}

.moretab {
    border-top: 1px solid #eeeeee;
    cursor: pointer;
    height: 50px;
    text-align: center;
    font-size: 16px;
}

.moretab > td {
    border-right: 1px solid #dcdcdc;
}

.realtime-table-pc-tr-bottom > td {
    border-bottom: 1px solid #eeeeee;
}


.realtime-table-mobile-tr-bottom > td,
.moretab > td {
    border-bottom: 1px solid #eeeeee;
}

.realtime-table tr.pc-hour-line {
    background-color: #eeeeee;
    border: 1px solid #dcdcdc !important;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: #999999;
    font-size: 12px;
}

/*
tr.pc-tr-cancel td:before {
    content: " ";
    position: absolute;
    top: 20%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 100%;
}
*/


.realtime-table-pc-tr .price {
    text-align: right;
    font-weight: bold;
    padding-right: 30px;
    border-right: 1px solid #dcdcdc;
}

.realtime-table-pc-tr > th,
.excel-table-pc-tr > th {
    font-weight: bold;
    height: 50px;
    font-size: 18px;
    text-align: center;
    border-bottom: 2px solid #999999;
}

.navigation.active,
.priceInfo.active,
.realtime-table.active,
.salesstats-content > .date.active,
.salesstats-content > .selectType.active,
.salesstats-content > .salesStats-table.active,
.salesstats-content > .graph.active,
.salesstats-content > .content-area.active,
#calctable > .halfsize.active,
.setting-content > .box.active {
    display: none;
}

.navigation {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: white;
    font-size: 18px;
    text-align: center;
    background-color: #46B476;
    border-bottom: 1px solid #3b9964;
}

.navigation > .left {
    float: left;
    cursor: pointer;
    width: 80px;
    height: 100%;
}

.navigation > .center {
    display: inline-block;
    margin: 0 auto;
    width: 125px;
    font-size: 20px;
    cursor: pointer;
    height: 100%;
}

.navigation > .right, .navigation > .right_disabled {
    float: right;
    cursor: pointer;
    width: 90px;
    height: 100%;
}

.navigation > .right_disabled {
    cursor: no-drop;
}

.navigation > .right_disabled span {
    opacity: 0.5;
}

.priceInfo {
    line-height: 80px;
    height: 80px;
    width: 100%;
    background-color: #46B476;
}

.priceInfo > .calcreport > .left,
.priceInfo > .salereport > .left {
    display: inline-block;
    font-size: 20px;
}

.priceInfo > .calcreport > .right,
.priceInfo > .salereport > .right {
    display: inline-block;
    font-size: 38px;
    font-weight: bold;
    margin-right: 20px;
    margin-left: 20px;
}

.priceInfo .won {
    font-size: 20px;
    font-weight: 400;
}

.priceInfo > .calcreport {
    color: white;
    float: left;
    text-align: right;
    width: 48%;

}

.priceInfo > .salereport {
    float: right;
    width: 50%;
    text-align: right;
    height: 100%;
    color: white;
}

.realtime-table-pc-tr, .realtime-table-pc-tr td {
    font-size: 16px;
    display: vendys;
}

.realtime-table-mobile-tr, .realtime-table-mobile-tr td {
    cursor: pointer;
    display: none;
}

#realtime .yesterday {
    cursor: pointer;
}

.leftBold {
    font-weight: bold;
    width: 80px;
}

#excelBtn {
    float: right;
}

.excelbtn {
    width: 15px;
    margin-right: 5px;

}


.menuTable {
    min-height: 250px;
    width: 370px;
}

.btn-cancel {
    width: 100px;
    height: 30px;
    line-height: 16px;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 16px;
    color: #999999;
    border: 1px solid #999999 !important;
    padding: 0 !important;
}

.btn-sikdae {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: #46B476;
    font-size: 16px;
    font-weight: 200;
    padding: 0px;
    color: #ffffff;
    padding: 0 !important;
}

.btn-sikdae:hover {
    color: #fff;
}

.payModal {
    width: 640px;
}

.border-1px {
  border: 1px solid;
}

.menuTable .menuHead {
    height: 40px;
    font-size: 16px;
    background-color: #eeeeee;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.menuTable .menuTr {
    height: 50px;
    font-size: 15px;
}

.menuTable .menuTr > .mobilemenu {
    width: 40%;
}

.menuTable .menuTr > .mobilecount {
    width: 10%;
}

.menuTable th,
.menuTable td {
    text-align: center;
}

.menuTable .border {
    border-top: 1px solid #dcdcdc;
}

.menuTable .menus {
    width: 30%;
    text-align: left;
    padding-left: 20px;

}

.menuTable .price {
    /* 	width: 100px; */
    text-align: right;
    padding-right: 15px;
    width: 30%;
}

.menuTable .price .won {
    font-weight: bold;
}

.payInfoTable {
    height: auto;
    float: left;
    width: 210px;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
}

.payInfoTable tr {
    height: 40px;
}

.payInfoTable .TableBorder {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.payInfoTable tr.half {
    height: 10px;
}

.payInfoTable tr.paymentStats {
    height: 20px;
}


.payInfoTable .companyInfo {
    height: 60px;

}

.mobilemenutotal {
    line-height: 40px;
    padding: 15px;
    width: 100%;
    font-weight: bold;
}

.mobilemenutotal > .name {
    float: left;
    font-size: 22px;
}

.mobilemenutotal > .won {
    float: right;
    font-size: 36px;
    /* 	padding-right: 12px; */
}

.mobilemenutotal > .won > .text {
    font-size: 22px;
}

.menutotal {
    line-height: 80px;
    padding: 20px;
}

.menutotal .name {
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
    font-size: 22px;
    color: #333333;

}

.menutotal .price {
    font-size: 36px;
    color: #333333;
    font-weight: bold;

}

.menutotal .price .won {
    font-size: 22px;
}


.paymentInfo {
    margin-left: 10px;
    margin-right: 10px;
}

.paymentInfo > .moreInfo {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 22px;
}

.paymentInfo > .moreInfo td {
    font-size: 18px;
}

.statusleft {
    width: 110px;
    font-size: 18px;
    font-weight: bold;
}

.paymentInfo > .page {
    background-color: white;

    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
}

.paymentInfo > .page > .number {
    padding: 10px 0;
    font-size: 22px;
    text-indent: 20px;
    border-bottom: 1px solid #dcdcdc;
}

.paymentInfo > .page > .date {
    padding: 10px 0;
    font-size: 22px;
    text-indent: 20px;
    border-bottom: 1px solid #dcdcdc;
}


.paymentInfo > .page > .people {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 22px;
    padding-left: 20px;
    font-weight: bold;
}

.calc-old-content > .waitCalc {
    text-align: center;
}

.calc-old-content > .waitCalc p {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: bold;
}

.calc-old-content > .waitCalc img {
    width: 70%;
    max-width: 300px;

}

.calcCompanyInfo > .moreInfo {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 22px;
}

.calcCompanyInfo .paymentStatus,
.calcCompanyInfo .paymentType,
.calcCompanyInfo .paymentResult,
.calcMonthInfo .paymentStatus,
.calcMonthInfo .paymentType,
.calcMonthInfo .paymentResult {
    font-size: 18px;
}

.calcCompanyInfo > .page {
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
}

.calcCompanyInfo > .page > .number {
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    text-indent: 20px;
    border-bottom: 1px solid #dcdcdc;
}

.calcCompanyInfo > .page > .date {
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    text-indent: 20px;
    border-bottom: 1px solid #dcdcdc;
}


.calcCompanyInfo > .page > .people {
    height: 50px;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px;
    font-weight: bold;
}

.calcCompanyInfo > .page > .price {
    border-top: 1px solid #dcdcdc;
}

.calcCompanyInfo > .page > .price > .rows {
    height: 40px;
    line-height: 40px;
}

.calcCompanyInfo > .page > .price > .rows > .title {
    display: inline-block;
    font-size: 18px;
    color: #333333;
    padding-left: 20px;
}

.calcCompanyInfo > .page > .price > .rows > .info {
    float: right;
    padding-right: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

.calcCompanyInfo > .page > .totalprice {
    border-top: 1px solid #dcdcdc;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
}

.calcCompanyInfo > .page > .totalprice > .rows > .title {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    height: 40px;
    padding-left: 20px;
}

.calcCompanyInfo > .page > .totalprice > .rows > .info {
    float: right;
    height: 40px;
    padding-right: 20px;
    text-align: right;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.calcCompanyInfo > .page > .totalprice > .rows > .info > .won {
    font-size: 20px;
}

.calcCompanyInfo > .page > .message {
    padding-left: 20px;
    color: #999999;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pconly {
    display: none;
}

.calendarMobile {
    text-align: center;
    background-color: white;

}

#calendarPC .modal-dialog {
    text-align: center;
    /* width: 300px; */
    width: 345px;
}

.calcMonthInfo {
    margin-left: 10px;
    margin-right: 10px;
}

.calcMonthInfo > .moreInfo {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 22px;
}

.calcMonthInfo .companys {
    font-size: 14px;
    line-height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: white;

}

.calcMonthInfo .companys .com {
    background-color: #eeeeee;
    border-bottom: 1px solid #dcdcdc !important;
    border-top: 1px solid #dcdcdc !important;
    height: 60px;
    line-height: 60px;
    color: #999999;
    font-size: 12px;
}

.calcMonthInfo .companys .left {
    display: inline-block;
    padding-left: 10px;
    font-size: 16px;
    color: #333333;
    height: 50px;
}

.calcMonthInfo .companys .price {
    float: right;
    padding-right: 5px;
    font-size: 20px;
    color: #333333;
    height: 50px;
    font-weight: bold;
}

.calcMonthInfo .companys .price img {
    width: 20px;
    height: 20px;
}

/* .calcMonthInfo .companys .price .won { font-size: 18px; } */

.calcMonthInfo > .page {
    background-color: white;

    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.calcMonthInfo > .page > .number {
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    text-indent: 20px;
    border-bottom: 1px solid #dcdcdc;
}

.calcMonthInfo > .page > .date {
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    text-indent: 20px;
}


.calcMonthInfo > .page > .people {
    margin-top: 10px;
    height: 80px;
    font-size: 22px;
    padding-left: 20px;
    font-weight: bold;
}


.calcMonthInfo > .page > .price {
    border-top: 1px solid #dcdcdc;
}

.calcMonthInfo > .page > .price > .rows {
    height: 40px;
    line-height: 40px;
}

.calcMonthInfo > .page > .price > .rows > .title {
    display: inline-block;
    font-size: 18px;
    color: #333333;
    padding-left: 20px;
}

.calcMonthInfo > .page > .price > .rows > .info {
    float: right;
    padding-right: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

.calcMonthInfo > .page > .totalprice {
    border-top: 1px solid #dcdcdc;
    margin-top: 10px;
    padding-top: 10px;
    height: 40px;
    line-height: 40px;
}

.calcMonthInfo > .page > .totalprice > .rows > .title {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    height: 40px;
    padding-left: 20px;
}

.calcMonthInfo > .page > .totalprice > .rows > .info {
    float: right;
    height: 40px;
    padding-right: 20px;
    text-align: right;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.calcMonthInfo > .page > .totalprice > .rows > .info > .won {
    font-size: 20px;
}

.calcMonthInfo > .page > .message {
    padding-left: 20px;
    color: #999999;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
}


/* Sales Stats */

.salesstats-content .date {
    width: 100%;
    line-height: 60px;
    text-align: center;
    background-color: #46B476;
    height: 60px;
    border-left: 1px solid #3B9964;
    border-right: 1px solid #3B9964;
    border-top: 1px solid #3B9964;
}


.salesstats-content .date .left {
    width: 20%;
    color: white;
    display: inline-block;
    /* 	padding-left: 20px; */
    height: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
}

.salesstats-content .date .right {
    display: inline-block;
    width: 80%;
    text-align: center;
}

.salesstats-content .date .right .todate {
    color: white;
    font-size: 20px;
    width: 90%;
    height: 40px;
    display: inline-block;
}

.salesstats-content .date .right .todate .string {
    width: 100%;
    line-height: 40px;
    height: 40px;
    background-color: #3B9964;
    border: 1px solid #37925F;
}

.salesstats-content .selectType {
    background-color: white;
    height: 40px;
}

.salesstats-content .selectType .active {
    color: #46b476;
    background-color: white;
    font-weight: bold;
    border-bottom: 3px solid #46B476 !important;
}

.salesstats-content .selectType .type3 {
    cursor: pointer;
    width: 33.4%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    display: inline-block;
}

.salesstats-content .content-area {
    ::-webkit-scrollbar {
        display: block;
        height: 12px;
    }
    ::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 30px;
        border: 2px solid #f7f7f7;
    }
}

.salesstats-content .content-area .payment-tip {
    margin-top: 20px;
}

.salesstats-content .content-area .payment-tip .ant-collapse-header {
    padding: 18px 16px;
    font-size: 18px;
    font-weight: 700;
}

.salesstats-content .content-area .payment-tip .ant-collapse-header .ant-collapse-arrow {
    font-size: 18px;
    vertical-align: -3px;
}

.salesstats-content .content-area .payment-table {
    padding-top: 20px;
    overflow-x: scroll;
}

.salesstats-content .selectType .type1,
.salesstats-content .selectType .type2 {
    cursor: pointer;
    width: 25%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    display: inline-block;
}

.salesstats-content .selectType .type1 {
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.salesstats-content .selectType .type1 .active {
    border: 0px solid transparent;
}

.salesstats-content .selectType .type2 .active {
    border: 0px solid transparent;
}

.salesstats-content .selectType .type3 .active {
    border: 0px solid transparent;
}

.salesstats-content .selectType .type2,
.salesstats-content .selectType .type3 {
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.salesstats-content .selectType .type3 {
    border-right: 1px solid #dcdcdc;
    width: 33.4%;
}

.salesstats-content .graph {
    width: 48%;
    float: left;
    margin-top: 20px;
    margin-right: 2%;
    background-color: white;
    min-height: 200px;
    display: inline-block;
    border: 1px solid #dcdcdc;
}

.salesStats-table {
    float: left;
    margin-top: 20px;
    width: 50%;
}

.menu-log-salesStats-table {
    width: 100%;
    border: 1px solid #444444;
    border-collapse: collapse;
}

.back-color-green {
    background-color: rgba(70, 180, 118, 0.12);
}

.bgc-white {
    background-color: #ffffff
}

.bgc-gray {
  background-color: #eeeeee
}

.menu-check-box {
    width: 50px;
    text-align: center;
}

.menu-status-box {
    width: 90px;
    text-align: center;
}

.text-center {
    text-align: center;
}

.font-color-white {
    color: white;
}

.font-color-gray {
  color: #999999;
}

.font-color-green {
  color: #46b476;
}

.margin-top-30 {
    margin-top: 30px;
}

.padding-left-10px {
    padding-left: 10px;
}

.padding-left-20px {
    padding-left: 20px;
}

.menu-log-box {
    padding: 10px 20px;
}

.modal-size {
    width: 538px;
}

.margin-left-5px {
    margin-left: 5px;
}

.menu-group {
    width: 100%;
    height: 23px;
    font-size: 20px;
    font-weight: bold;
    line-height: 2.2;
    padding-left: 10px;
    color: #ffffff;
}

.menu-table-header {
    height: 45px;
    background-color: #666666;
}

.menu-category {
    height: 45px;
    background-color: #cccccc;
}

.menu-log-row-box {
    height: 45px;
    background-color: white;
}

.menu-box {
    height: 45px;
    background-color: #cccccc;
}

.menu-list {
    height: 80px;
}

.menu-status-div-box {
    height: 80px;
    width: 90px;
    vertical-align: middle;
    display: inherit;
}

.padding-right-10px {
    padding-right: 10px;
}

.padding-right-20px {
  padding-right: 20px;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-18px {
  font-size: 20px;
}

.font-size-16px {
  font-size: 16px;
}

.font-size-14px {
  font-size: 14px;
}

.img-size-16-16 {
  width: 16px;
  height: 16px;
}

.inline-block {
  display: inline-block;
}

.menu-all-box {
    height: 45px;
    padding-left: 10px;
    vertical-align: middle;
    display: inherit;
}

.menu-category-check-div-box {
    height: 45px;
    width: 50px;
    vertical-align: middle;
    display: inherit;
}

.menu-check-div-box {
    height: 80px;
    width: 50px;
    vertical-align: middle;
    display: inherit;
}

.menu-data-null {
    text-align: center;
}

.menu-data-null > span {
    line-height: 4.5;
    font-size: 1.6rem;
}

.menu-name {
    width: 75%;
    height: 35px;
    font-size: 20px;
    line-height: 2.5;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 600px;
}

.mobile-menu-name {
  font-size: 20px;
  padding: 16px 0px 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-menu-info {
  height: 20px;
  padding-left: 50px;
  color: #999999;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-info {
    width: 75%;
    height: 24px;
    line-height: 2.5;
    padding-left: 10px;
    float: left;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 600px;
}

.category-box-set {
  height: 64px;
  display: table-cell;
  vertical-align: middle;
}

.mastersalesStats-table {
    margin-top: 10px;
    width: 100%;
    border: 1px solid #dcdcdc;
}

.mastersalesStats-table thead tr th.last {
    border-right: 1px solid #dcdcdc;
    padding-right: 15px;
}

.mastersalesStats-table tr.cutline {
    background-color: #eeeeee;
    border: 1px solid #dcdcdc !important;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: #999999;
    font-size: 12px;
    width: 100%;
}

.mastersalesStats-table tr.cutline td {
    border-right: 1px solid #dcdcdc !important;
}

.mastersalesStats-table tr.dataRow {
    height: 40px;
    width: 100%;
    font-size: 15px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #dcdcdc !important;
}

.mastersalesStats-table tr.dataRow td.textInfo,
.mastersalesStats-table th.textInfo {
    text-align: left;
    padding-left: 15px;
}

.mastersalesStats-table tr.dataRow td.priceLabel,
.mastersalesStats-table th.priceLabel {
    text-align: right;
    font-weight: bold;
}


.mastersalesStats-table tr.dataRow td.last {
    border-right: 1px solid #dcdcdc !important;
    padding-right: 15px;
}


.mastersalesStats-table tr.more {
    border: 1px solid #dcdcdc !important;
    background-color: #fff;
    cursor: pointer;
    height: 40px;
    text-align: center;
    font-size: 16px;
}


.mastersalesStats-table thead th {
    text-align: center;
    background-color: #e2e2e2;
    height: 40px;
    font-size: 18px;
}

.MasterSalesstats-content .date {
    width: 100%;
    line-height: 60px;
    text-align: center;
    background-color: #46B476;
    height: 60px;
    border-left: 1px solid #3B9964;
    border-right: 1px solid #3B9964;
    border-top: 1px solid #3B9964;
}


.MasterSalesstats-content .date .left {
    width: 50%;
    color: white;
    display: inline-block;
    /*  padding-left: 20px; */
    height: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 15px;
}

.MasterSalesstats-content .date .left .str {
    width: 25%;
    text-align: center;
    display: inline-block;
}

.MasterSalesstats-content .date .left .types {
    width: 75%;
    display: inline-block;
}

.MasterSalesstats-content .date .left .types .btn1,
.MasterSalesstats-content .date .left .types .btn2 {
    width: 40%;
    padding-left: 3%;
    display: inline-block;
}

.MasterSalesstats-content .date .left .types .btn1 .string,
.MasterSalesstats-content .date .left .types .btn2 .string {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border: 2px solid white;
    color: white;

}

.MasterSalesstats-content .date .left .types .btn1 .active,
.MasterSalesstats-content .date .left .types .btn2 .active {
    background-color: white;
    font-weight: bold;
    color: #46b476;

}

.MasterSalesstats-content .date .right {
    display: inline-block;
    width: 50%;
    text-align: center;
}

.MasterSalesstats-content .date .right .todate {
    color: white;
    font-size: 20px;
    width: 90%;
    height: 40px;
    display: inline-block;
}

.MasterSalesstats-content .date .right .todate .string {
    width: 100%;
    line-height: 40px;
    height: 40px;
    background-color: #3B9964;
    border: 1px solid #37925F;
}

.MasterSalesstats-content .optionType {
    height: 40px;
    margin-top: 10px;
    line-height: 40px;
}

.MasterSalesstats-content .optionType .storeSelect {
    width: 200px;
    float: left;
    margin-left: 10px;
    float: left;
    height: 40px;
}

.MasterSalesstats-content .optionType .toggle {
    float: left;
    line-height: 40px;
    margin-top: 2px;
    margin-right: 5px;
    width: 30px;
}

.MasterSalesstats-content .optionType .comapnySelect select {
    float: left;
    width: 150px;
}

.MasterSalesstats-content .optionType .comapnySelect {
    margin-left: 20px;
    width: 200px;
    float: left;
}

.MasterSalesstats-content .optionType .menu {
    float: left;

}

.salesStats-table tr.total-sum-line {
    background-color: #46B476;
    color: #FFFFFF;
    font-size: 15px;
    text-align: right;
}

.salesStats-table tr.total-sum-line .won {
    padding-right: 20px;
}

.salesStats-table tr.year-line {
    background-color: #eeeeee;
    border: 1px solid #dcdcdc;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: #999999;
    font-size: 12px;
}

.salesStats-table tr.salesstats-month {
    height: 60px;
    background-color: white;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.salesStats-table tr.salesstats-month .month {
    width: 100%;
    cursor: pointer;
}

.salesStats-table tr.salesstats-month .month .won {
    font-size: 20px;
    color: #333333;
}

.salesStats-table tr.salesstats-month .month .left {
    font-size: 18px;
    padding-left: 20px;
    color: #999999;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    width: 20%;
}

.salesStats-table tr.salesstats-month .month .right {
    font-size: 30px;
    text-align: right;
    width: 80%;
    padding-right: 20px;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
    color: #333333;
    display: inline-block;
}

.salesStats-table tr.salesstats-month .month .right .arrow {
    width: 25px;
    height: 25px;
    margin-left: 15px;
}

.salesStats-table .salesstats-daily,
.salesStats-table .salesstats-info {
    width: 100%;
    background-color: #f7f7f7;
    border: 1px solid #dcdcdc;
}

.salesStats-table .salesstats-daily .won {
    font-size: 18px;
    color: #666666;
}

.salesStats-table .salesstats-info .type {
    font-size: 15px;
    padding-left: 20px;
    color: #999999;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 	max-width: 160px; */
    /* 	width: 50%; */
    float: left;
}

.salesStats-table .salesstats-info .right .left {
    font-size: 15px;
    color: #999999;
    height: 40px;
    line-height: 40px;
    /* 	max-width: 160px; */
    /* 	width: 50%; */
    float: left;
}

.salesStats-table .salesstats-info .right .left {
    float: left;
    width: 40px;
    text-align: center;
}

.salesStats-table .salesstats-info .right {
    font-size: 15px;
    /* 	padding-left: 20px; */
    color: #999999;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    width: 180px;
    float: right;
}


.salesStats-table .salesstats-info .right .info {
    font-size: 17px;
    text-align: right;
    width: 140px;
    float: right;
    height: 40px;
    line-height: 40px;
    padding-right: 20px;
    font-weight: bold;
    color: #666666;

}

.salesStats-table .salesstats-daily .type {
    font-size: 16px;
    padding-left: 15px;
    color: #999999;
    height: 60px;
    line-height: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.ele3 {
    position: fixed;
    background-color: #f7f7f7;
    width: 100%;
}

.salesStats-table .salesstats-daily .onlyright {
    font-size: 20px;
    color: #666;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    padding-right: 20px;
    font-weight: bold;
    width: 180px;
    float: right;
}

.salesStats-table .salesstats-daily .onlyright .won {
    font-size: 18px;
}

.salesStats-table .salesstats-daily .right {
    font-size: 16px;
    /* 	padding-left: 20px; */
    color: #999999;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    min-width: 180px;
    width: auto;
    float: right;
}


.salesStats-table .salesstats-daily .right .left {
    min-width: 40px;
    width: auto;
    float: left;
}


.salesStats-table .salesstats-daily .right .info {
    font-size: 20px;
    text-align: right;
    width: 140px;
    float: right;
    height: 60px;
    line-height: 60px;
    padding-right: 20px;
    font-weight: bold;
    color: #666666;

}

#dateModal .calendar {
    display: inline-block;
    width: 48%;
}

#dateModal .calendar .dateInfo .left {
    padding-left: 20px;
    line-height: 40px;
    font-weight: bold;
    float: left;
}

#dateModal .calendar .dateInfo .right {
    float: right;
    width: 200px;
    margin-top: 5px;
    padding-top: 5px;
    margin-right: 10px;
    text-align: center;
    height: 30px;
    background-color: #eeeeee;
    border: 1px solid #dcdcdc;
    border-radius: 5px;

}

#dateModal .preset {
    display: inline-block;
    float: right;
    width: 45%;
}

#dateModal .dateInfo {
    height: 40px;
}

#dateModal .calendar .draw {
    margin: 0 auto;
    text-align: center;
}

#dateModal .preset .btns .thisMonth:hover,
#dateModal .preset .btns .oneweek:hover,
#dateModal .preset .btns .today:hover,
#dateModal .preset .btns .prevMonth:hover,
#dateModal .preset .btns .twoMonth:hover,
#dateModal .preset .btns .twoweek:hover,
#dateModal .preset .btns .statusAll:hover,
#dateModal .preset .btns .statusComplete:hover,
#dateModal .preset .btns .statusSuccess:hover,
#dateModal .preset .btns .statusIng:hover,
#dateModal .preset .btns .statusCancel:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

#dateModal .preset .btns .thisMonth,
#dateModal .preset .btns .oneweek,
#dateModal .preset .btns .today,
#dateModal .preset .btns .prevMonth,
#dateModal .preset .btns .twoMonth,
#dateModal .preset .btns .twoweek,
#dateModal .preset .btns .statusAll,
#dateModal .preset .btns .statusComplete,
#dateModal .preset .btns .statusSuccess,
#dateModal .preset .btns .statusIng,
#dateModal .preset .btns .statusCancel {
    cursor: pointer;
    display: inline-block;
    width: 80px;
    height: 30px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    margin-top: 10px;
    color: #999999;
}

#dateModal .preset .btns .active {
    color: white !important;
    background-color: #333333 !important;
}


#datemobile {
    background-color: white;
}

#datemobile .calendar {
    display: inline-block;
    width: 100%;
}

#datemobile .calendar .dateInfo {
    margin-top: 10px;
}

#datemobile .calendar .dateInfo .left {
    padding-left: 20px;
    font-size: 18px;
    display: inline-block;
    line-height: 40px;
    font-weight: bold;
}

#datemobile .calendar .dateInfo .right {
    float: right;
    min-width: 200px;
    /* 	margin-top:5px; */
    line-height: 30px;
    /* 	padding-top:5px; */
    margin-right: 10px;
    text-align: center;
    height: 30px;
    background-color: #eeeeee;
    border: 1px solid #dcdcdc;
    border-radius: 5px;

}

#datemobile .preset {
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    border-top: 1px solid #dcdcdc;
}

#datemobile .preset h4 {
    margin-left: 10px;
    margin-top: 20px;
    font-weight: bold;
}

#datemobile .dateInfo {
    height: 40px;
}

#datemobile .calendar .draw {
    margin: 0 auto;
    text-align: center;
}

#datemobile .preset .btns {
    text-align: center;
    margin-bottom: 20px;
}

#datemobile .preset .btns .thisMonth:hover,
#datemobile .preset .btns .oneweek:hover,
#datemobile .preset .btns .today:hover,
#datemobile .preset .btns .prevMonth:hover,
#datemobile .preset .btns .twoMonth:hover,
#datemobile .preset .btns .twoweek:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

#datemobile .preset .btns .thisMonth,
#datemobile .preset .btns .oneweek,
#datemobile .preset .btns .today,
#datemobile .preset .btns .prevMonth,
#datemobile .preset .btns .twoMonth,
#datemobile .preset .btns .twoweek {
    cursor: pointer;
    display: inline-block;
    width: 90px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    font-size: 17px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    margin-top: 10px;
    color: #999999;
}

#datemobile .preset .btns .active {
    color: white !important;
    background-color: #333333 !important;
}

#datemobile .footer {
    background-color: white;
    height: 100px;
    text-align: right;
    margin-right: 10px;
    margin-left: 10px;
    border-top: 1px solid #dcdcdc;
}

#datemobile .footer button {
    margin-top: 10px;
}

.btn-sikdae-wide {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background-color: #46B476;
    font-size: 24px;
    font-weight: bold;
    padding: 0px;
    color: #ffffff;
}

/* calculate */


.calc-content .halfsize {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}

.calc-content .halfsize .contents {
    border: 1px solid #dcdcdc;
}


.calc-content .halfsize .contents .month {
    background-color: #eeeeee;
    border-bottom: 1px solid #dcdcdc !important;
    border-top: 1px solid #dcdcdc !important;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: #999999;
    font-size: 12px;
}

.calc-content .halfsize .title {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    background-color: #ecf7f1;
    border-bottom: 1px solid #d2e1d9;
}

.calc-content .halfsize .title .left {
    float: left;
    padding-left: 20px;
    font-size: 14px;
    color: #46B476;
    height: 40px;
}

.calc-content .halfsize .title .left img {
    width: 15px;
    height: 14px;
    margin-top: -2px;
}

.calc-content .halfsize .title .right {
    float: right;
    padding-right: 20px;
    font-size: 16px;
    color: #46B476;
    height: 40px;
}

.calc-content .halfsize .price {
    font-size: 14px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    background-color: white;
    border-bottom: 1px solid #dcdcdc;
    font-weight: bold;
}

.calc-content .halfsize .price .left {
    float: left;
    padding-left: 20px;
    font-size: 18px;
    color: #999999;
    height: 60px;
}

.calc-content .halfsize .price .right {
    float: right;
    padding-right: 20px;
    font-size: 30px;
    color: #333333;
    height: 60px;
}

.calc-content .halfsize .price .right .won {
    font-size: 20px;
}

.calc-content .halfsize .companys {
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    background-color: white;
    border-bottom: 1px solid #dcdcdc;
}

.calc-content .halfsize .companys .left {
    float: left;
    padding-left: 20px;
    font-size: 16px;
    color: #999999;
    height: 50px;
}

.calc-content .halfsize .companys .right {
    float: right;
    padding-right: 20px;
    font-size: 20px;
    color: #666666;
    height: 50px;
    font-weight: bold;
}

.calc-content .halfsize .companys .right .won {
    font-size: 18px;
}

.display-flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}


/*pc 버전에서만 적용*/
@media (min-width: 768px) {

    .web-display-none {
        display: none;
    }

    .pconly {
        display: block;
    }

    .mobileonly {
        display: none;
    }

    #excelBtn:hover {
        opacity: 0.5;
    }

    .excel-download {
        cursor: pointer;
        margin-top: 10px;
        margin-right: 10px;
        font-size: 16px;
        color: #fff;
        width: 180px;
        height: 40px;
        border-radius: 5px;
        line-height: 35px !important;
        background: transparent;
        border: 1px solid #fff;
    }
    .excel-download.vat-excel {
        width: 300px;
    }

    .excel-download a,
    .excel-download a:hover,
    .excel-download a:focus {
        color: #fff;
        text-decoration: none;
    }

    .navbar-text {
        float: left;
        margin-right: 15px;
        margin-left: 15px;
    }


    .navbar-left {
        float: left !important;
    }

    .navbar-right {
        float: right !important;
        margin-right: -f
    }

    .navbar-right ~ .navbar-right {
        margin-right: 0;
    }
}

/* excelModal */

#excelModal .info {
    margin-left: 20px;
    font-size: 15px;
    color: #333333;
}

#excelModal .dateBtn {
    margin-left: 20px;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
}

#excelModal .dateBtn .defaults {
    cursor: pointer;
    display: inline-block;
    width: 80px;

    margin-right: 10px;
    text-align: center;
    line-height: 30px;
    height: 30px;
    border-radius: 5px;
    color: #999999;
    border: 1px solid #999999;
}

#excelModal .dateBtn .defaults:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #333333;
}

#excelModal .dateBtn .selected {
    color: white;
    background-color: #333333;
    border-color: #333333;
}

#excelModal .date {
    display: inline-flex;
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
}

#excelModal .date .to {
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;

}

#excelModal .date .dateSet img {
    width: 14px;
    margin-top: -2px;
    margin-left: 10px;
}

#excelModal .date .dateSet span {
    height: 30px;
    line-height: 30px;
    margin-left: 10px;
    margin-right: 10px;

}

#excelModal .date .dateSet {
    text-align: center;
    width: 300px;
    height: 30px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    font-size: 15px;
    color: #999999;
}

#excelModal .date .startdate input,
#excelModal .date .enddate input {
    width: 80px;
    margin-left: 15px;
    text-align: center;
    padding-top: 3px;
    border: 0 solid transparent;
}

/* login */

.mobile-login {
    height: auto;
    position: absolute;
    width: 100%;
    background-color: #fff;
}
@media (min-height: 670px) {
    .mobile-login {
        height: 100%;
    }
}

.mobile-login .head {
    text-align: center;
}

.mobile-login .head img {
    width: 160px;
    margin-top: 35px;
    margin-bottom: 20px;
}

.mobile-login .serverStatus,
.pc-login .serverStatus {
    font-weight: bold;
    color: black;
    text-decoration: underline;
}

.mobile-login .content {
    margin-left: 30px;
    margin-right: 30px;
}

.mobile-login .content p {
    font-size: 22px;
    color: #999999;
    margin-left: 10px;
    margin-top: 5px;
}

.mobile-login .content input {
    display: inline-block;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    font-size: 22px;
}

.mobile-login .content .autologin {
    height: 30px;
    margin-top: 12px;
    line-height: 30px;
}

.mobile-login p.autologin {
    margin-left: 0px;
}

.mobile-login .content .autologin img {
    width: 30px;
}

.mobile-login .hr {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    background-color: #EEEEEE;
}

.mobile-login .btnArea {
    text-align: center;
    margin: 0 auto;
    margin-left: 30px;
    margin-top: 20px;
    margin-right: 30px;
    padding-bottom: 20px;
}

.mobile-login .loginBtn {
    height: 60px;
    color: white;
    font-weight: bold;
    font-size: 24px;
    border-radius: 5px;
    background-color: #46B476;
}


.pc-login {
    position: absolute;
    top: 20%;
    width: 100%;
}

.pc-login > .warp {
    width: 520px;
    margin: 0 auto;
}

.modal-header .head {
    font-size: 22px;
    color: #999999;
}

.modal-header .head .point {
    font-weight: bold;
    margin-left: 5px;
    color: #46B476;
}

.modal-new-password-text {
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
    padding-bottom: 10px;
}

.pc-login .head {
    font-size: 22px;
    color: #999999;
    margin-bottom: 5px;
}

.pc-login .head .point {
    font-weight: bold;
    margin-left: 5px;
    color: #46B476;
}

.pc-login .content {
    width: 520px;
    height: 340px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.pc-login .content .left img {
    width: 150px;
    margin-top: 10px;
}

.pc-login .content .left {
    float: left;
    width: 270px;
    height: 270px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 10%;
}

.pc-login .content .right {
    float: right;
    width: 250px;
    height: 270px;
    padding-top: 10%;
}

.pc-login .content .right p {
    font-size: 15px;
    height: 15px;
    margin-top: 10px;
    color: #999999;
}

.pc-login .content .right input {
    width: 200px;

    height: 30px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
}

.pc-login .hr {
    width: 100%;
    height: 1px;
    float: left;
    background-color: #EEEEEE;
}


.pc-login .content .right .autologin {
    height: 30px;
    margin-top: 12px;
    line-height: 30px;
}

.pc-login .content .right p.autologin {
    margin-left: 0px;
}

.pc-login .content .right .autologin img {
    width: 23px;
}

.pc-login .btnArea {
    height: 70px;
    float: left;
    width: 100%;
}

.pc-login .btnArea .loginBtn {
    width: 100px;
    float: right;
    height: 30px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 15px;
    border-radius: 5px;
    background-color: #46B476;
}


/* monthStatModal */

#monthMonthModal .leftblock {
    width: 40%;
    margin-right: 20px;
    margin-left: 20px;
    float: left;
}

#monthMonthModal .rightblock {
    display: inline-block;
    width: 50%;
    margin-right: 20px;
    border-top: 1px solid #dcdcdc;
}

#monthMonthModal .rightblock .companys {
    border-top: 1px solid #dcdcdc;
    margin-bottom: 20px;
}

#monthMonthModal .rightblock .companys .com {
    height: 60px;
    background-color: #eeeeee;
    width: 100%;
    font-size: 15px;
    line-height: 60px;
    color: #333333;
    cursor: pointer;
    border-bottom: 1px solid #dcdcdc;
}

#monthMonthModal .rightblock .companys .com .left {
    display: inline-block;
    text-align: left;
    padding-left: 20px;
    height: 60px;
    width: 35%;
    float: left;
}

#monthMonthModal .rightblock .companys .com .price {
    display: inline-block;
    width: 60%;
    text-align: right;
    font-weight: bold;
    font-size: 22px;
}

#monthMonthModal .rightblock .companys .com .price img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
}

#monthMonthModal .rightblock .companys .com .price .won {
    font-size: 18px;
}

/* companystatsModal */
.calcModal {
    width: 750px;
}

#monthStatsModal .leftblock {
    display: inline-block;
    width: 40%;
    margin-left: 20px;
}

#monthStatsModal .rightblock {
    /* 	display: inline-block; */
    float: right;
    width: 50%;
    margin-right: 20px;
}

#monthStatsModal .rightblock .cell,
#monthMonthModal .rightblock .cell {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    color: #333333;
}

#monthStatsModal .rightblock .cell .infoName,
#monthMonthModal .rightblock .cell .infoName {
    display: inline-block;
    text-align: left;
    width: 20%;
}

#monthStatsModal .rightblock .cell .price,
#monthMonthModal .rightblock .cell .price {
    display: inline-block;
    text-align: right;
    width: 80%;
    font-weight: bold;
}

#monthStatsModal .rightblock .tprice,
#monthMonthModal .rightblock .tprice {
    border-top: 1px solid #dcdcdc;
}

#monthStatsModal .rightblock .message,
#monthMonthModal .rightblock .message {
    margin-top: 20px;
    margin-bottom: 20px;
    color: gray;
}

#monthStatsModal .rightblock .tprice .infoName,
#monthMonthModal .rightblock .tprice .infoName {
    display: inline-block;
    text-align: left;
    font-size: 22px;
    width: 25%;
}

#monthStatsModal .rightblock .tprice .price,
#monthMonthModal .rightblock .tprice .price {
    display: inline-block;
    font-size: 40px;
    text-align: right;
    width: 74%;
    font-weight: bold;
}

#monthStatsModal .rightblock .tprice .price .won,
#monthMonthModal .rightblock .tprice .price .won {
    font-size: 22px;
}

#monthStatsModal .leftblock .date,
#monthMonthModal .leftblock .date {
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    font-weight: bold;
}

#monthStatsModal .leftblock .companyName {
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    font-weight: bold;
    border-bottom: 1px solid #dcdcdc;
}

#monthStatsModal .leftblock .other {
    margin-top: 10px;
}

#monthStatsModal .leftblock .other .stats {
    width: 100%;
    font-size: 15px;
    height: 30px;
}

#monthStatsModal .leftblock .other .stats .left {
    display: inline-block;
    font-weight: bold;
    margin-left: 0px;
    width: 100px;
}

#monthStatsModal .leftblock .other .stats .right {
    display: inline-block;
}

#monthStatsModal .leftblock .other .stats .right .wait {
    color: red;
}

#monthStatsModal .leftblock .other .message {
    margin-top: 5px;
    margin-bottom: 20px;
}


.setting-content .box {
    background-color: white;
    border: 1px solid #dcdcdc;
    color: #333333;
}

.setting-content .box .passwordbox {
    cursor: pointer;
    line-height: 60px;
    font-size: 18px;
    border-bottom: 1px solid #dcdcdc;
    padding-top: 20px;
    padding-left: 20px;
    font-weight: bold;
}

.setting-content .box .passwordbox {
    display: none;
}

.setting-content .box .passwordbox.active {
    display: inherit;
}

.setting-content .box .passwordbox p {
    margin: 0px;

}

.setting-content .box .passwordbox input {
    width: 30%;
}

.setting-content .box .passwordbox input#oldpassword {
    font-size: 15px;
}

.setting-content .box .passwordbox input#newpassword,
.setting-content .box .passwordbox input#newpassword2 {
    font-size: 15px;
    height: 40px;
    font-weight: bold;
}

.setting-content .box .passwordbox input#newpassword2 {
    margin-top: 10px;
}

.setting-content .box .passwordbox .sbtn {
    text-align: right;
    margin-top: 10px;
    height: 40px;
    margin-bottom: 10px;
}

.setting-content .box .passwordbox .changepassword {
    cursor: pointer;
    float: right;
    line-height: 30px;
    width: 130px;
    height: 30px;
    border-radius: 8px;
    background-color: #46B476;
    color: #fff;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 200;
    text-align: center;
}

.setting-content .box .cfPasswordbox {
    cursor: pointer;
    line-height: 60px;
    font-size: 18px;
    border-bottom: 1px solid #dcdcdc;
    padding-top: 20px;
    padding-left: 20px;
    font-weight: bold;
}

.setting-content .box .cfPasswordbox {
    display: none;
}

.setting-content .box .cfPasswordbox.active {
    display: inherit;
}

.setting-content .box .cfPasswordbox p {
    margin: 0px;

}

.setting-content .box .cfPasswordbox input {
    width: 30%;
}

.setting-content .box .cfPasswordbox input#oldpassword {
    font-size: 15px;
}

.setting-content .box .cfPasswordbox input#newpassword,
.setting-content .box .cfPasswordbox input#newpassword2 {
    font-size: 15px;
    height: 40px;
    font-weight: bold;
}

.setting-content .box .cfPasswordbox input#newpassword2 {
    margin-top: 10px;
}

.setting-content .box .cfPasswordbox .sbtn {
    text-align: right;
    margin-top: 10px;
    height: 40px;
    margin-bottom: 10px;
}

.setting-content .box .cfPasswordbox .changepassword {
    cursor: pointer;
    float: right;
    line-height: 30px;
    width: 130px;
    height: 30px;
    border-radius: 8px;
    background-color: #46B476;
    color: #fff;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 200;
    text-align: center;
}

.setting-content .box .tab {
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    font-size: 18px;
    border-bottom: 1px solid #dcdcdc;
    padding-left: 20px;
    font-weight: bold;

}

.setting-content .box .arrow {
    width: 20px;
    height: 20px;
    margin-top: 20px;
    margin-right: 10px;
}

.setting-content .box .toggle {
    width: 62px;
    height: 31px;
    margin-top: 20px;
    margin-right: 10px;
}

.setting-content .box .left {
    float: left;
    width: 70%;
}

.setting-content .box .right {
    float: right;

}

.setting-content .box .voice {
    height: 80px;
    line-height: 30px;
    font-size: 18px;
    padding-left: 20px;
    border-bottom: 1px solid #dcdcdc;
    font-weight: bold;
    padding-top: 10px;
}

.setting-content .box .voice .left {
    font-size: 22px;
}

.setting-content .box .voice .notice {
    font-size: 14px;
    color: #999999;
    font-weight: 200;
}

.setting-content .box .myid {
    height: 80px;
    line-height: 20px;
    padding-top: 15px;
    padding-left: 20px;
    font-size: 18px;
    border-bottom: 1px solid #dcdcdc;

}

.setting-content .box .myid p {
    font-size: 16px;
    color: #999999;
}

.setting-content .box .logout {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 60px;
    padding-top: 15px;
}

.setting-content .box .logout .logoutbtn {
    cursor: pointer;
    line-height: 30px;
    width: 130px;
    height: 30px;
    border-radius: 8px;
    background-color: #333333;
    color: #fff;
    float: right;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 200;
    text-align: center;

}

.setting-content .box .logout .withdrawbtn {
    cursor: pointer;
    float: right;
    width: 80px;
    height: 30px;
    margin: 20px 20px 0px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    text-decoration: underline;
    color: #AFB3B8;
    background: transparent;
    border: none;
}

.mobile-password {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
}

.mobile-password p {
    font-size: 17px;
    color: #333333;
    height: 60px;
    line-height: 60px;
}

.mobile-password input#oldpassword {
    font-size: 18px;
    height: 50px;
    font-weight: bold;
}

.mobile-password input#newpassword,
.mobile-password input#newpassword2 {
    font-size: 18px;
    height: 50px;
    font-weight: bold;
}

.mobile-password input#newpassword2 {
    margin-top: 20px;
}

.mobile-password .sbtn {
    margin-top: 40px;
}

.mobile-password .sbtn .changepassword {
    width: 100%;
    background-color: #46B476;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    font-size: 23px;
    color: white;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

.mobile-password .sbtn .changepassword-modal {
    background-color: #46B476;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    font-size: 23px;
    color: white;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

.mobile-password .sbtn .recommended-modal {
    background-color: #46B476;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    font-size: 23px;
    color: white;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 767px) {
    .modal-password-image {
        width: 178px;
        margin-left: 19px;

    }
}

@media (max-width: 767px) {
    .modal-password-image {
        width: 178px;
    }
}

.modal-password-box {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
}

.modal-password-box p {
    font-size: 17px;
    color: #333333;
    height: 38px;
    line-height: 35px;
}

.modal-password-box input#oldpassword {
    font-size: 18px;
    height: 42px;
    font-weight: bold;
}

.modal-password-box input#newpassword,
.modal-password-box input#newpassword2 {
    font-size: 18px;
    height: 42px;
    font-weight: bold;
}

.modal-password-box input#newpassword2 {
    margin-top: 15px;
}

.modal-password-box .sbtn {
    margin-top: 40px;
}

.modal-password-box .sbtn .changepassword {
    width: 100%;
    background-color: #46B476;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    font-size: 23px;
    color: white;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

.text-float-right {
    float: right;
}

.sbtn .changepassword-modal {
    width: 165px;
    height: 32px;
    border-radius: 5px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
}

.sbtn .recommended-modal {
    width: 110px;
    height: 32px;
    border-radius: 5px;
    background-color: #32ba55;
}

.sbtn .recommended-modal .bg_Box {
    width: 512px;
    height: 344px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.excel-content .realtime-table tbody tr {
    text-align: center;
    height: 50px;
    font-size: 16px;
    color: #333333;
}


.realtime-table-pc-tr td.menuInfo,
.realtime-table-pc-tr > th.menuInfo {
    text-align: left;
}


/* 구 정산 */

#calcoldtable {
    margin: 20px;
}

.oldcalc-table {
    width: 100%;
    background-color: white;
    border: 1px solid #dcdcdc;
    text-align: center;
}

.oldcalc-table .greenpoint {
    color: #37C26F;
}

.oldcalc-table tr.calc-old-thead th {
    text-align: center;
    height: 60px;
    background-color: #eeeeee;
    font-size: 16px;
    border-bottom: 1px solid #dcdcdc;
}

.oldcalc-table tbody tr {
    height: 50px;
    border-bottom: 1px solid #dcdcdc;
}


.oldcalc-morepc {
    cursor: pointer;
    height: 50px;
    text-align: center;
    font-size: 16px;
}


.oldcalc-morem {
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    background-color: white;
    border: 1px solid #dcdcdc;
    text-align: center;
    font-size: 16px;

}

/* mobile */

.mobileList {
    display: none;
}

.mobileList .contents .dateInfo {
    width: 100%;
    height: 50px;
    font-weight: bold;
    line-height: 50px;
    background-color: #eeeeee;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    font-size: 18px;
}

.mobileList .contents .dateInfo .title {
    width: 30%;
    font-size: 16px;
    text-align: left;
    padding-left: 20px;
    display: inline-block;
}

.mobileList .contents .dateInfo .date {
    width: 70%;
    font-size: 16px;
    display: inline-block;
    color: #666666;
    text-align: right;
    padding-right: 10px;

}


.mobileList .contents .text {
    background-color: white;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
}

.mobileList .contents .text .pointcolor {
    color: #46B476
}

.mobileList .contents .text .title {
    width: 30%;
    text-align: left;
    font-weight: bold;
    padding-left: 20px;
    display: inline-block;
}

.mobileList .contents .text .string {
    width: 70%;
    font-size: 18px;
    text-align: right;
    display: inline-block;
    padding-right: 10px;
}


/* excel */

.excel-table tbody .infoTr {
    height: 50px;
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #dcdcdc;
}

.excel-table tbody .infoTr td.downLink {
    text-decoration: underline;
    cursor: pointer;
}


/* master main */

.main-mastergraph .storegraphs {

    /* margin: 20px 20px 20px 20px; */
    border: 1px solid #dcdcdc;
    background-color: white;
}


.main-mastergraph .storegraphs .overlay {
    position: absolute;
    z-index: 999;
    top: 100px; /* chartArea top  */
    right: 50px; /* chartArea left */
}

.main-mastergraph .storegraphs > #reactgooglegraph-1 {
    margin-bottom: 20px;
}

/* loading */


/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 9999;
    height: 100%;
    width: 100%;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

/* Transparent Overlay */
.loading .spin {
    content: '';
    display: block;
    position: fixed;
    top: 50%;
    margin: 0 auto;
    left: 49%;
    height: 3em;
    width: 3em;
}

.spin:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;

    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(220, 220, 220, 0.75) 1.5em 0 0 0, rgba(220, 220, 220, 0.75) 1.1em 1.1em 0 0, rgba(220, 220, 220, 0.75) 0 1.5em 0 0, rgba(220, 220, 220, 0.75) -1.1em 1.1em 0 0, rgba(220, 220, 220, 0.5) -1.5em 0 0 0, rgba(220, 220, 220, 0.5) -1.1em -1.1em 0 0, rgba(220, 220, 220, 0.75) 0 -1.5em 0 0, rgba(220, 220, 220, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(220, 220, 220, 0.75) 1.5em 0 0 0, rgba(220, 220, 220, 0.75) 1.1em 1.1em 0 0, rgba(220, 220, 220, 0.75) 0 1.5em 0 0, rgba(220, 220, 220, 0.75) -1.1em 1.1em 0 0, rgba(220, 220, 220, 0.75) -1.5em 0 0 0, rgba(220, 220, 220, 0.75) -1.1em -1.1em 0 0, rgba(220, 220, 220, 0.75) 0 -1.5em 0 0, rgba(220, 220, 220, 0.75) 1.1em -1.1em 0 0;
}

.menu-row {
    width: 305px;
    height: 80px;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.1);
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@media (max-width: 767px) {


    #page-content-wrapper {
        display: inherit;
        overflow-y: visible;
    }


    #sidebar-wrapper {
        overflow-y: visible;
    }


    .sidebar-nav li a {
        height: 70px;
        line-height: 70px;
    }

    .menuName {
        font-size: 26px;
    }

    .cancelNum {
        display: none;
    }

    .realtime-content, .index, .salesstats-content, .calc-content, .setting-content {
        margin: 0px 0px 0px 0px;

    }


    .multiple-store > .storename {
        font-size: 26px;
        margin: 5px 0 10px
    }

    .multiple-store > .change {
        font-size: 22px;

    }

    .sidebar-nav > .contactus {
        font-size: 26px;
        height: 180px;
    }

    .sidebar-nav > .contactus {
        height: 180px;
    }

    .sidebar-nav > .contactus > .hr {
        display: block;
    }

    .sidebar-nav > .contactus > .contacticon {
        margin-top: 15px;
    }

    .sidebar-nav > .contactus > .denhwa > .info,
    .sidebar-nav > .contactus > .message > .info {
        font-size: 24px;
    }

    .sidebar-nav > .contactus > .message > .image, .sidebar-nav > .contactus > .message > .info {
        display: block;
    }

    .sidebar-nav > .contactus > .denhwa > .image {
        padding-top: 5px;
    }

    .sidebar-nav li div img {
        margin-top: -10px;
        width: 28px;
    }

    .sidebar-nav > .setting {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 60px;
        height: 60px;
    }

    .realtime-table-mobile-tr {
        display: block;
        border-bottom: 1px solid #dcdcdc;
    }

    .realtime-table-mobile-tr td {
        display: block;
    }

    .realtime-table-pc-tr, .realtime-table-pc-tr td {
        display: none;
    }


    .realtime-table {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    #wrapper.active > #page-content-wrapper {
        display: none;
    }

    .realtime-table tr.pc-hour-line {
        display: none;
    }

    tr.mobile-hour-line {
        display: block;
    }

    .menuTable {
        width: 97%;
        min-height: 100px;
        margin: 0 auto;
    }

    .menuTable > thead > tr.menuHead {
        font-size: 20px;
    }

    .menuTable > thead > tr.menuHead > .menus {
        padding-left: 15px;
    }

    .menuTable > thead > tr.menuHead > .count {
        width: 20%;
    }

    .menuTable > tbody > tr.menuTr {
        font-size: 18px;
        height: 65px;
    }

    .menuTable > tbody > tr.menuTr .mobilemenu {
        padding-left: 15px;
    }

    .sidebar-nav > .contactus {
        margin: 30px auto;
    }

    .index .quickicons .qbtn {
        width: 145px;
        display: inline-block;
        /*
		margin-right: 5px;
		margin-left: 5px;
*/

    }

    .index {
        border: 0px solid transparent;
    }

    .index .block1, .index .block2 {
        width: 80%;
    }

    .index .block1 {
        margin-right: 0px;
    }

    .index .quickicons .qbtn {
        width: 50%;
    }


    .index .quickicons .qbtn .box {
        margin-top: 10px;
        width: 95%;
        height: 130px;
        background-color: #ffffff;
        font-size: 22px;
        color: #333333;
        padding-top: 25px;
    }

    .index .quickicons .qbtn .box img {
        width: 50px;
    }

    .index .storepay, .index .cancelbox, .index .mainGuideBtn {
        width: 80%;
    }

    .index .storepay, .index .cancelbox, .index .quickicons .qbtn .box .name {
        font-size: 22px;
    }

    .index .quickicons .qbtn .last {
        width: 95% !important;
    }

    .index .top .monthSell .left-pc {
        display: none;
    }

    .index .top .todaySell .left {
        padding-left: 15px;
    }

    .index .top .monthSell .left-mobile {
        display: block;
        float: left;
        line-height: 24px;
        color: white;
        font-size: 18px;
        padding-left: 15px;
        padding-top: 10px;
        text-align: left;
        height: 70px;
    }

    .priceInfo > .calcreport {
        display: none;
    }

    .priceInfo > .salereport {
        width: 100%;
        text-align: right;
        margin-right: 20px;
    }

    .priceInfo > .salereport > .right {
        margin-right: 0px;
    }

    .salesstats-content .date {
        border-top: 0px solid transparent;
    }

    .salesstats-content .graph {
        border: 0px solid transparent;
        width: 100%;
        margin-top: 0px;
    }


    .salesstats-content .date .left {
        line-height: 60px;
        padding-left: 0px;
        font-size: 16px;
        width: 23%;
    }

    .salesstats-content .date .right {
        width: 76%;
    }

    .salesstats-content .date .right .todate .string {
        font-size: 15px;
    }

    .salesstats-content .selectType,
    .salesstats-content .selectType .type1,
    .salesstats-content .selectType .type2,
    .salesstats-content .selectType .type3 {
        line-height: 60px;
        height: 60px;
    }


    .salesStats-table {
        width: 100%;
        margin-top: 0px;
    }

  .log-box {
    height: 120px;
  }

  /*.menu-salesStats-table {*/
  /*  width: 90%;*/
  /*  margin-top: 152px;*/
  /*  margin-left: 0;*/
  /*  table-layout: fixed;*/
  /*}*/

    .border-zero {
        border: 0;
    }

    .mobile-fixed-top-bar {
        position: fixed;
        width: 101%;
        background-color: #333333;
    }

    /*.menu-salesStats-table, th, td {*/
    /*    border-collapse: collapse;*/
    /*    border-left: hidden;*/
    /*}*/

    .margin-top-15 {
        margin-top: 15%;
    }

  .margin-top-10px {
    margin-top: 10px;
  }

  .modal-dialog.margin-top-50 {
    top: 50%;
    margin-top: -97.5px;
  }

    .modal-size {
        width: 100%;
    }

    .margin-top-minus {
        margin-top: -10px;
    }

    .media-display-none {
        display: none;
    }

    .text-right {
        text-align: right;
        float: right;
    }

    .mobile-menu-bt-box {
        width: 100%;
        height: 88px;
        background-color: #222222;
    }

  .inactive-view-box {
    width: 100%;
    height: 64px;
    background-color: white;
    display: table-cell;
    vertical-align: middle;
  }

  .mobile-menu-bt-in-box {
    width: 25%;
    display: inline-block;
    height: 100%;
    text-align: center;
    padding-top: 1.3em;
  }

    .mobile-menu-info-box {
        width: 100%;
        height: 88px;
    }

  .media-menu-info {
    width: 75%;
    height: 24px;
    line-height: 2.5;
    padding-left: 10px;
    float: left;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 600px;
  }

  .menu-box, .menu-list {
    width: 100%;
    float: left;
  }

  .menu-name, .menu-info {
    max-width: 220px;
  }

  .font-color-white {
    color: white;
  }

    .back-color-white {
        background-color: white;
    }

    .back-color-green {
        background-color: rgba(70, 180, 118, 0.12);
    }

    .img-box {
        width: 32px;
        height: 32px;
        object-fit: cover;
    }

  .status-img-box {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  .mobile-menu-group {
    font-size: 20px;
    font-weight: bold;
    padding-left: 8px;
    color: #333333;
    vertical-align: middle;
  }

  .mobile-box-width-25 {
    width: 25%;
  }

  .mobile-box-width-75 {
    width: 75%;
  }

  .menu-price-name {
    height: 35px;
    font-size: 20px;
    margin: 16px 10px 6px 0px;
    white-space: nowrap;
  }

  .menu-price-info {
    padding-right: 10px;
  }

  #dateModal .calendar {
    width: 100%;
  }

    #dateModal .preset {
        float: none;
        width: 100%;
        padding-left: 20px;
        margin-bottom: 20px;
        border-top: 1px solid #dcdcdc;
    }

    #dateModal .preset .btns {
        text-align: center;
    }

    .calc-content .halfsize {
        width: 100%;
        margin-top: 0px;
    }

    .calc-content .halfsize .companys, .calc-content .halfsize .price {
        height: 80px;
        line-height: 80px;
    }

    .calc-content .halfsize .price .left {
        height: 80px;
        font-size: 22px;
    }

    .calc-content .halfsize .price .right {
        font-size: 29px;
        height: 80px;
    }

    .calc-content .halfsize .price .right .won {
        font-size: 22px;
        height: 80px;
    }

    .calc-content .halfsize .companys .left {
        font-size: 18px;
        height: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0px;
        margin-left: 20px;
        width: 45%;
    }

    .calc-content .halfsize .companys .right .won {
        font-size: 20px;
        height: 80px;
        width: 50%;
    }

    .setting-content .box .myid {
        font-size: 22px;
    }

    .setting-content .box .myid p {
        font-size: 18px;
    }

    .setting-content .box .tab {
        height: 70px;
        line-height: 70px;
        font-size: 22px;
    }

    .setting-content .box .voice .notice {
        font-size: 11px;
    }

    .setting-content .box .arrow {
        margin-top: 25px;
    }

    .cancelNum {
        display: none;
    }


    #calcoldtable {
        margin: 0px;
    }

    .oldcalc-table {
        display: none;
    }

    .mobileList {
        display: inline;
    }

    .setting-content .box .logout {
        margin: 20px auto;
        padding-top: 0px;
    }

    .setting-content .box .logout .logoutbtn {
        width: 90%;
        height: 60px;
        line-height: 60px;
        font-weight: bold;
        font-size: 24px;
    }


    /* fix mobile page */
    .salesstats-content .graph {
        margin-right: 0px;
    }

    .salesStats-table .salesstats-daily,
    .salesStats-table .salesstats-info,
    .salesStats-table tr.salesstats-month,
    .salesStats-table tr.year-line {
        border-right: 0px solid transparent;
        border-left: 0px solid transparent;
    }

}

.v2_iframe {
    display: block;
    border: none;
    height: 100%;
    width: 100%;
}

.v2_iframe_h {
    display: block;
    border: none;
    height: 100vh;
    width: 100%;
}

.dimmed {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 290px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}


@media screen and (min-width: 780px) {
    .dim .dimmed {
        display: block;
    }
}

.text-overflow-dotdotdot {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* 설정 패스워드 변경 */
.setting-content .password-change-wrap {
    height: auto;
    position: inherit;
    width: auto;
}

.setting-content .password-change.screen {
    height: auto;
    position: inherit;
    width: auto;
}

.setting-content .password-change.screen .password-chnage-header {
    padding: 10px 30px 24px 30px;
    width: 100%;
    font-size: 24px;
    margin: 0;
}

.setting-content .box .passwordbox .password-change input {
    width: 100%;
}

.padding-bottom20 {
    padding-bottom: 20px;
}

@media screen and (min-width: 780px) {
    .setting-content .password-change.screen {
        left: 0;
        margin-left: 0;
    }
}

/* .setting-content .box .passwordbox p.desc {
	font-size: 16px;
	color: #707371;
	font-weight: normal;
	line-height: 1.5;
}

.setting-content .box .passwordbox .password-chnage-header p {
	font-size: 24px;
	font-weight: normal;
	line-height: 1.3;
}

.mobile-password .password-chnage-header p {
	font-size: 24px;
	font-weight: normal;
	line-height: 1.3;
} */

/* 패스워드 변경 */
.password-change-wrap {
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: #fff;
}

.password-change.screen {
    position: absolute;
    width: 100%;
    background-color: #fff;
}

.password-change.screen .password-chnage-header {
    padding: 60px 40px 24px 40px;
    width: 300px;
    font-size: 24px;
}

.password-change .password-chnage-header {
    padding: 0 40px;
    width: 100%;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.38;
}

.password-change p {
    line-height: 1.53;
}

.password-chnage-header p {
    padding: 0;
    margin: 0;
    display: inline-block;
    line-height: 1.38;
}

.password-change .content {
    margin: 0 40px
}

.password-change .content p {
    font-size: 19px;
    color: #222222;
    margin-left: 0;
    margin-top: 5px;
    font-weight: bold;
}

.password-change .content p.re {
    margin-top: 32px;
}

.password-change .content p.desc {
    font-size: 16px;
    color: #707371;
    font-weight: normal;
}

.password-change .content input {
    display: inline-block;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    font-size: 16px;
}

.password-change .content input.valid {
    border-color: #1db53a;
}

.password-change .content input.invalid {
    border-color: #dd3337;
}

.modal-body .password-change .content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.modal-body .password-change .content.spec-item {
    justify-content: flex-start;
    margin: 0;
    padding: 0 40px 40px;

}
.modal-body .password-change .content.spec-item .flex-item {
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    margin: 0;
}
.modal-body .password-change .content.spec-item p {
    display: inline-block;
}
.modal-body .password-change .content.spec-item p:first-child {
    margin-right: 8px;
}

.modal-body .password-change .content .flex-item {
    width: 400px;
    margin-bottom: 30px;
}

.modal-body .password-change .content .flex-item:first-child {
    margin-right: 0;
}

.modal-body .password-change .content input:read-only {
    background-color: #fff;
}

.modal-header .close {
    opacity: 1;
}

.password-example-modal .modal-dialog {
    max-width: 700px;
}

.ex {
    line-height: 22px;
    font-size: 16px;
    color: #19a333;
    text-align: center;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 32px;
    display: table;
}

.ex a {
    color: #19a333 !important;
}

.ex::before {
    content: url(../images/login/ic-help.svg);
    vertical-align: middle;
    display: table-cell;
    padding-right: 6px;
}

.password-change .btnArea {
    text-align: center;
    margin: 0 auto;
    padding-left: 30px;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
    display: flex;
}

.password-change .btnArea .btn-block + .btn-block {
    margin-top: 0;
    margin-left: 4px;
}

.password-change .loginBtn {
    height: 60px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    background-color: #32ba55;
}

.password-change .loginBtn:disabled {
    background-color: #dddddd;
}

.password-change .btn-default {
    font-size: 16px;
}

.password-change .validation-wrap {
    line-height: 21px;
    font-size: 14px;
    margin-top: 8px;
}

.password-change .validation {
    line-height: 21px;
    font-size: 14px;
    margin: 3px 0 3px 22px;
    display: table;
}

.password-change .validation.invalid {
    color: #dd3337;
}

.password-change .validation.invalid::before {
    content: url(../images/login/ic-pw-error.svg);
    vertical-align: middle;
    display: table-cell;
    padding-right: 6px;
}

.password-change .validation.valid {
    color: #1db53a;
}

.password-change .validation.valid::before {
    content: url(../images/login/ic-pw-success.svg);
    vertical-align: middle;
    display: table-cell;
    padding-right: 6px;
}

#evidenceAgencyModal .modal-header,
#passwordModal .modal-header {
    border-bottom: none;
    background-color: #fff;
}

#evidenceAgencyModal .modal-footer,
#passwordModal .modal-footer {
    border-top: none;
}

.oval-wrap {
    padding: 30px 29px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.oval {
    width: 60px;
    height: 60px;
    border: solid 1px #a6a6a6;
    font-size: 19px;
    font-weight: bold;
    color: #222222;
    display: inline-block;
    border-radius: 30px;
    line-height: 60px;
    text-align: center;
    margin: 0 10px 50px
}

/* .oval:last-child {
	border: 0;
} */
.oval::after {
    content: attr(name);
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
}

@media (min-width: 768px) {
    .password-example-modal .password-chnage-header p:last-child {
        margin-left: 10px;
    }

    .password-change .btnArea .btn-block + .btn-block {
        margin-top: 0;
        margin-left: 20px;
    }

    .modal-body .password-change .content {
        flex-flow: row nowrap;
    }

    .modal-body .password-change .content .flex-item:first-child {
        margin-right: 20px;
    }

    .password-example-modal .modal-dialog {
        min-width: 700px;
    }
}

@media (min-width: 800px) {
    .password-change.screen {
        max-width: 460px;
        margin-left: -230px;
        left: 50%;
    }
}

.filter {
    margin-top: 30px;
}

.filter-form {
    display: flex;
}

.filter-form .form-group {
    width: 200px;
    margin-right: 5px;
}

.filter-form .form-group.empty {
    flex: 1 0 auto;
}

.filter-form .form-group .guide .succeed {
    color: #4c9aff;
    font-weight: bold;
}

.filter-form .form-group .guide .cenceled {
    color: #ffc400;
    font-weight: bold;
}


.filter-form button[type=submit] {
    width: 120px;
    height: 34px;
    align-self: flex-end;
    margin-bottom: 16px;
}

.shipping-modal-form {
    margin: 0 15px;
}

.table-info, .table-info > th, .table-info > td {
    background-color: rgba(255, 223, 126, 0.7);
}

.table-error, .table-error > th, .table-error > td {
    background-color: rgba(238, 13, 13, 0.7);
}

#shipping .shipping-search-btn {
    height: 34px;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 5px;
}

#shipping .btn-area {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;
    height: 34px;
    display: flex;
    align-items: flex-end;
}

#shipping .btn-area .notice-label {
    color: red;
    vertical-align: bottom;
    margin-right: 20px;
}

#shipping .btn-area .shipping-cancel-btn {
    width: 120px;
    margin: 0 5px;
}

#shipping .btn-area .shipping-save-btn {
    height: 34px;
    width: 120px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 5px;
    background-color: #32ba55;
    margin: 0 5px;
}

#shipping .status-used {
    color: #4c9aff;
}

#shipping .status-part-cancel {
    color: #ffc400;
}

#shipping .status-cancel {
    color: red;
}

.table-td-cursor-pointer {
    cursor: pointer;
}

.table-row {
    text-align: center;
    font-size: 16px;
    height: 50px;
}

.table-row th {
    font-weight: bold;
    height: 50px;
    font-size: 18px;
    text-align: center;
}


.reserveStatus-content .selectType,
.reserveStatus-content .selectType .type1,
.reserveStatus-content .selectType .type2,
.reserveStatus-content .selectType .type3 {
    line-height: 60px;
    height: 60px;
}


.reserveStatus-content .date {
    width: 100%;
    line-height: 60px;
    text-align: center;
    background-color: #46B476;
    height: 60px;
    border-left: 1px solid #3B9964;
    border-right: 1px solid #3B9964;
    border-top: 1px solid #3B9964;
}


.reserveStatus-content .date .left {
    width: 20%;
    color: white;
    display: inline-block;
    /* 	padding-left: 20px; */
    height: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
}

.reserveStatus-content .date .right {
    display: inline-block;
    width: 80%;
    text-align: center;
}

.reserveStatus-content .date .right .todate {
    color: white;
    font-size: 20px;
    width: 90%;
    height: 40px;
    display: inline-block;
}

.reserveStatus-content .date .right .todate .string {
    width: 100%;
    line-height: 40px;
    height: 40px;
    background-color: #3B9964;
    border: 1px solid #37925F;
}

.reserveStatus-content .selectType {
    background-color: white;
    height: 40px;
}

.reserveStatus-content .selectType .active {
    color: #46b476;
    background-color: white;
    font-weight: bold;
    border-bottom: 3px solid #46B476 !important;
}

.reserveStatus-content .selectType .type3 {
    cursor: pointer;
    width: 33.4%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    display: inline-block;
}

.reserveStatus-content .selectType .type1,
.reserveStatus-content .selectType .type2 {
    cursor: pointer;
    width: 33.3%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    display: inline-block;
}

.reserveStatus-content .selectType .type1 {
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.reserveStatus-content .selectType .type1 .active {
    border: 0px solid transparent;
}

.reserveStatus-content .selectType .type2 .active {
    border: 0px solid transparent;
}

.reserveStatus-content .selectType .type3 .active {
    border: 0px solid transparent;
}

.reserveStatus-content .selectType .type2,
.reserveStatus-content .selectType .type3 {
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.reserveStatus-content .selectType .type3 {
    border-right: 1px solid #dcdcdc;
    width: 33.4%;
}

.selectDesign {
    min-width: 200px;
    border: 1px solid #DBDBDB;
    padding: 5px 10px;
    margin-top: 10px;
}

.reserveStatusTable {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #dcdcdc;
    background-color: #ffffff;
}

.reserveStatusTable > thead > tr > th {
    height: 50px;
    text-align: center;
    border-top: 1px solid #dcdcdc;

}

.reserveStatusTable > tbody > tr > td {
    height: 50px;
    text-align: center;
    border-top: 1px solid #dcdcdc;
}

.border-bottom {
    border-bottom: 1px solid #dcdcdc;
    border-top: 0px solid #dcdcdc !important;
}

.reserveStatusTable-menu-td-first {
    text-align: left !important;
    padding-left: 20px;
    background-color: #dcdcdc;
}


.shippingDetailModalTable {
    width: 94%;
    margin: 20px 20px 20px 20px;
    border: 1px solid #dcdcdc;
    background-color: #ffffff;
}

.shippingDetailModalTable > thead > tr > th {
    height: 50px;
    text-align: center;
    border-top: 1px solid #dcdcdc;

}

.shippingDetailModalTable > tbody > tr > td {
    height: 50px;
    border-top: 1px solid #dcdcdc;

}

.shippingDetailHeader {
    background-color: #dcdcdc;
    text-align: center;
    width: 160px;
}

.shippingDetailContent {
    text-align: left;
}

.shippingDetailContent > div {
    text-align: left;
    margin-left: 5px;
}

.push-send-button {
    height: 30px;
    min-height: 30px;
    margin-left: 10px;
    line-height: 1;
    border-radius: 7px 7px 7px 7px;
}

.modal-xlg {
    width: 1300px !important;
}

.modal-xlg > .modal-content > .modal-body {
    height: 520px;
}

.text-left-important {
    text-align: left !important;
    padding-left: 5px;
}

.mb-3 {
    margin-bottom: 10px;
}

.push-send-sample-message {
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.push-message-sample {
    cursor: pointer;
}

.push-message-sample > input[type="radio"] {
    margin-left: 15px;
    margin-right: 15px;
}

.under-line-text {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    width: 450px;
}

.margin-left-8px {
  margin-left: 8px;
}

.margin-left-1 {
    margin-left: 5px;
}

.margin-left-2 {
    margin-left: 10px;
}

.margin-left-3 {
    margin-left: 15px;
}

#shippingModal h3 {
    margin-bottom: 32px;
    font-weight: bold;
}

#shippingModal hr {
    margin-top: 40px;
    margin-bottom: 40px;
}

#shippingModal table th {
    font-size: 1.2rem;
}

.no-content {
    text-align: center;
}

.total-content {
    font-weight: bold;
}

.min-height-auto {
    min-height: auto !important;
}

.padding-zero {
    padding: 0 !important;
}

.table .total-price, .total-price {
    text-align: right;
}

.margin-bottom {
    margin-bottom: 50px;
}

.padding-left-1 {
    padding-left: 5px;
}

.mobile-login .btnArea .custom-button-channel {
    font-size: 20px;
    line-height: 1.5;
    color: #999999;
    padding: 15px;
    margin-top: 8px;
    margin-bottom: 30px;
    background-color: #ffffff;
}
#evidenceAgencyModal {
    font-family: Pretendard, 'Noto Sans Korean';
}
#evidenceAgencyModal .modal-dialog {
    max-width: 300px;
    margin: 10px auto auto;
}
#evidenceAgencyModal .modal-dialog .modal-header {
    padding: 12px;
}
#evidenceAgencyModal .modal-dialog .modal-body {
    padding-top: 0;
    text-align: center;
    font-size: 14px;
}
#evidenceAgencyModal .modal-dialog .modal-header,
#evidenceAgencyModal .modal-dialog .modal-body .evidence-agency-header {
    background-color: #548fff;
}

#evidenceAgencyModal .modal-dialog .modal-body .evidence-agency-wrapper .content {
    background-color: #ffffff;
}
#evidenceAgencyModal .modal-dialog .modal-body .evidence-agency-wrapper .evidence-agency-header {
    color: #ffffff;
}

#evidenceAgencyModal .modal-dialog .modal-content {
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
}
#evidenceAgencyModal p, #evidenceAgencyAgreeComplete p {
    margin: 0;
}
#evidenceAgencyModal .modal-dialog .modal-body .evidence-agency-wrapper .title {
    font-size: 18px;
}
#evidenceAgencyModal .evidence-agency-img {
    margin-top: 16px;
    width: 166px;
}
#evidenceAgencyModal .evidence-agency-wrapper .agree-wrapper {
    margin: 24px 15px 8px;
    padding: 4px 0 4px 0px;
    border-radius: 4px;
    border: solid 1px #222222;
    display: flex;
    align-items: center;
    cursor: pointer;
}
#evidenceAgencyModal .evidence-agency-wrapper .agree-wrapper.active {
    background-color: #46b476;
    border: solid 1px #46b476;
    color: #ffffff;
}
#evidenceAgencyModal .evidence-agency-wrapper .agree-wrapper.active .before img{
    content: url(../images/other/ic-check-circle-white.svg);
}
#evidenceAgencyModal .evidence-agency-wrapper .agree-wrapper.active .after img{
    content: url(../images/arrow/arrow-right-16-white.svg);
}
#evidenceAgencyModal .evidence-agency-wrapper .agree-wrapper .agree {
    line-height: 1.43;
    /*height: 40px;*/
}
#evidenceAgencyModal .evidence-agency-wrapper .agree-wrapper .before,
#evidenceAgencyModal .evidence-agency-wrapper .agree-wrapper .after{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#evidenceAgencyModal .evidence-agency-wrapper .notice {
    line-height: 1.43;
    letter-spacing: normal;
    color: #999999;
    margin: 15px 16px 8px;
    text-align: left;
}
#evidenceAgencyModal .evidence-agency-wrapper .notice ul {
    list-style: none;
    margin: 0 0 0 12px;
    padding: 0;
}
#evidenceAgencyModal .evidence-agency-wrapper .notice ul li:before {
    content: 'ㆍ';
    margin-left: -12px;
}
#evidenceAgencyModal .btn-area {
    padding: 8px 16px 0;
}
#evidenceAgencyModal .agree-btn, #evidenceAgencyAgreeComplete .agree-btn {
    margin-bottom: 4px;
    padding: 12px 14px;
    border-radius: 4px;
    background-color: #46b476;
    font-size: 16px;
    color: #ffffff;
}
#evidenceAgencyModal .next-btn {
    margin-top: 4px;
    padding: 12px 16px 16px;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 14px;
    color: #999999;
}
.evidence-agency-banner {
    background-color: #548fff;
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 20px;
    cursor: pointer;
    background-image: url(../images/other/img-chr-receipt@3x.png);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-size: 166px;
    background-position-y: bottom;
}
.evidence-agency-banner .banner-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 40px;
}
.evidence-agency-banner .banner-text b {
    font-size: 18px;
}
.evidence-agency-banner p{
    margin: 0;
}
#evidenceAgencyAgreeModal {
    font-family: Pretendard, 'Noto Sans Korean';
    color: #222222;
}
#evidenceAgencyAgreeModal td, #evidenceAgencyAgreeModal th {
    padding: 8px 8px;
}
#evidenceAgencyAgreeModal tr td {
    border-right: none
}
#evidenceAgencyAgreeModal tr th {
    background-color: #eeeeee;
    min-width: 120px;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .title{
    font-size: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: none;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-body {
    padding: 0 16px;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-body ul {
    list-style: none;
    padding: 0;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-body th {
    font-weight: normal;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-header {
    background-color: #ffffff;
    padding: 18px;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-header .close {
    display: none;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .agree-btn {
    padding: 7px 36px 4px;
    border-radius: 5px;
    background-color: #46b476;
    color: #ffffff;
    font-size: 16px;
    width: auto;
    display: inline-block;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down table {
    margin: 19px 52px 40px;
}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .agree-cancel-btn {
    margin-right: 10px;
    display: inline-block;
    padding: 7px 36px 4px;
    border-radius: 5px;
    border: solid 1px #999999;
    background-color: #ffffff;

}
#evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-title {
    font-weight: normal;
}
#evidenceAgencyAgreeComplete {
    color: #222222;
    background-color: #ffffff;
    font-size: 14px;
}
#evidenceAgencyAgreeComplete .modal-dialog .modal-header {
    border-bottom: none;
    background-color: #ffffff;
    text-align: center;
}
#evidenceAgencyAgreeComplete .modal-dialog .modal-body .title {
    text-align: center;
    font-size: 18px;
}
#evidenceAgencyAgreeComplete .modal-dialog .modal-body {
    padding-top: 2px;
}
#evidenceAgencyAgreeComplete .modal-dialog .modal-body .content {
    padding: 24px 16px 8px;
}
#evidenceAgencyAgreeComplete .modal-dialog .modal-footer {
    border-top: none;
    padding-left: 16px;
    padding-right: 16px;
}
@media (max-width:767px) {
    .evidence-agency-banner .banner-text {
        margin-left: 20px;
    }
    .evidence-agency-banner {
        background-image: url(../images/other/img-chr-receipt-mobile@3x.png);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: 107px;
        background-position-y: bottom;
    }
    .evidence-agency-banner {
        margin-bottom: 0;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .title{
        display: block;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-content {
        border: none;
        border-radius: 0;
        height: 100%;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-header {
        border: none;
        border-radius: 0;
    }
    #evidenceAgencyAgreeModal tr th {
        min-width: 88px;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-header .close {
        float: left;
        display: block;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .agree-btn {
        padding: 12px 14px;
        border-radius: 4px;
        width: 100%;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .agree-cancel-btn {
        display: none;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-footer {
        border-top: none;
    }
    #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down table {
        margin: 0 0 47px;
    }
}

.w-85 {
    width : 85% !important;
}

.margin-top-20px {
 margin-top : 20px !important;
}

#dateModal .uploadInfo {
    display: inline-block;
    width: 100%;
}

#dateModal .uploadInfoMsg {
    padding-left: 20px;
    line-height: 40px;
    font-weight: bold;
    float: left;
}

#excelDateModal .side-space {
    padding-left: 40px;
}

#dropzone {
    height : 100%;
    vertical-align: middle;
}

#dropzone .excelZone {
    line-height: 40px;
    display: inline-block;
    width: 90%;
    height : 100%;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #E8E8E8;
    background-color: #F5F5F5;
    margin-top:3%;
    margin-left:5%;
    cursor:pointer;
}

.excelFileZone {
    line-height: 30px;
    padding-left: 40px;
    display: inline-block;
    width: 95%;
    height : 90%;
    text-align: center;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.excel-upload-file {
    padding-left: 30px;
    color : white;
}

.excelZoneMsg {
    color : #A9A9A9;
    font-weight: bold;
}

.excelZoneFileMsg {
    color : #A9A9A9;
    font-weight: bold;
    text-decoration : #A9A9A9 underline;
}
.font-wei-bold {
    font-weight : bold;
}

#excel-upload-warning .excel-upload-label {
    color: red;
    margin-left: 5%;
}

#excel-upload-info {
    padding-left: 0px;
    padding-right: 40px;
    line-height: 20px;
    display: inline-block;
    width: 90%;
    height : 95px;
    text-align: left;
    border-radius: 5px;
    border: 2px solid #E8E8E8;
    margin-top:3%;
    margin-left:5%;
    margin-bottom:15px;
    font-size: 14px;
    color : #808080;
    padding-top:13px;
}

.excel-download-link {
    margin-left: 5% !important;
    font-weight: bold;
    text-decoration : #A9A9A9 underline;
    cursor: pointer;
}

.btnDelete {
    width: 30px;
    height: 30px;
    overflow: hidden;
    color: white;
    padding-right:10px;
    font-size: 15px;
    font-weight: bold;
    margin-top:3px;
}

.excel-fail-table {
    width: 95% !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
}

.fail-download-btn {
    width: 160px;
    margin-bottom:10px !important;
    margin-top:25px !important;
    margin-right:25px !important;
}
.display-none {
    display : none !important;
}

.btn-file {
    background-color: #32ba55;
    height: 50px;
    padding: 10px;
    color: #ffffff
}

.excelFileZone .input-file .form-control {
    font-size: 14px;
}

.excelFileZone #attachExcelFile {
    display: inline-block;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    font-size: 16px;
}

.fail-status {
    color:red !important;
}
.excel-fail-table > tbody > tr > td,
.realtime-table > tbody > tr > td {
    vertical-align: middle !important;
}

#evidenceAgencyAgreeComplete .modal-dialog .modal-body .content {
  padding: 24px 16px 8px;
}

#evidenceAgencyAgreeComplete .modal-dialog .modal-footer {
  border-top: none;
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 767px) {
  .evidence-agency-banner .banner-text {
    margin-left: 20px;
  }

  .evidence-agency-banner {
    background-image: url(../images/other/img-chr-receipt-mobile@3x.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 107px;
    background-position-y: bottom;
  }

  .evidence-agency-banner {
    margin-bottom: 0;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .title {
    display: block;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-content {
    border: none;
    border-radius: 0;
    height: 100%;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-header {
    border: none;
    border-radius: 0;
  }

  #evidenceAgencyAgreeModal tr th {
    min-width: 88px;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-header .close {
    float: left;
    display: block;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .agree-btn {
    padding: 12px 14px;
    border-radius: 4px;
    width: 100%;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .agree-cancel-btn {
    display: none;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down .modal-footer {
    border-top: none;
  }

  #evidenceAgencyAgreeModal .modal-dialog.modal-fullscreen-md-down table {
    margin: 0 0 47px;
  }
}
/*#review*/
#page-content button[class*="btn-outline-"]:not(:hover):not(:focus):not(:active):not(:disabled) {
    background-color: #fff;
}
#review,
#review .review-container {
    height: 100%;
}
#review .content-area {
    height: calc(100% - 100px);
    overflow-y: auto;
    max-width: 800px;
    margin: auto;
}
#review .content-area .content {
    white-space: pre-line;
}
#review .content-area .text-muted,
#review .content-area .like-report {
    color: #AFB3B8;
}
#review .content-area .owner-reg-date {
    color: #AFB3B8;
    margin-left: 8px;
}
#review .review-score {
    display: flex;
    justify-content: space-between;
}
.review-score .text-muted {
    margin-left: 10px;
    vertical-align: text-bottom;
}
.review-score .menu {
    background-color: #e2e2e2e2;
    border-radius: 12px;
    padding: 2px 8px;
    display: inline-block;
    margin-top: 1rem;
}
.comment .comment-button-area {
    margin-top: 5px;
}
.background-color-transparent:not(:hover):not(:focus):not(:active):not(:disabled){
    background-color: transparent !important;
}
.comment .comment-button-area button:first-child {
    margin-right: 5px;
}
.comment .reade-only {
    position: relative;
    background: #f8f8f8;
    /*color: #fff;*/
    padding: 13px;
    border-radius: 20px;
    box-shadow: 2px 2px 3px #d1d1d1;
}
.comment .reade-only:before {
    border-top: 10px solid #f8f8f8;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0 solid transparent;
    content: " ";
    position: absolute;
    top: 8px;
    left: -10px;
}
.comment .reade-only .comment-button-area {
    text-align: right;
}
#review-report .modal-dialog {
    height: auto;
}
#reason-list .radio{
    margin-top: 0;
    margin-bottom: 0;
}
#review-report .description {
    padding-inline-start: 20px
}
.gallery {
    display: flex;
    flex-wrap: wrap;
    /* Compensate for excess margin on outer gallery flex items */
    margin: 0.3rem -1rem;
}
.gallery .gallery-item {
    /* Minimum width of 24rem and grow to fit available space */
    /*flex: 1 0 24rem;*/
    flex: 0 0 80px;
    /*flex-grow: 1;*/
    /*height: 40vh;*/
    height: 80px;
    /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
    margin: 0.3rem;
    box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
    overflow: hidden;
}
.gallery .gallery-item:last-child {
    /*flex-grow: 10;*/
}
.gallery .gallery-item img {
    display: block;
    vertical-align: bottom;
    transition: transform 400ms ease-out;
    cursor: pointer;
}
@supports (object-fit: cover) {
    .gallery .gallery-item img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .gallery-item img:hover {
        transform: scale(1.15);
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE 10+ */
    .gallery .gallery-item {
        position: relative;
        background-size: cover;
        background-position: 50% 50%;
    }
    .gallery .gallery-item img {
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
    }
    .gallery-item img:hover {
        transform: translate(-50%, -50%) scale(1.1);
    }
}

@media (max-aspect-ratio: 1/1) {
    .gallery .gallery-item {
        /*height: 30vh;*/
    }
}
@media (max-height: 480px) {
    .gallery .gallery-item {
        /*height: 80vh;*/
    }
}
@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
    .gallery {
        flex-direction: row;
    }

    .gallery .gallery-item {
        /*height: auto;*/
        width: 100%;
    }

    .gallery .gallery-item img {
        width: 100%;
        max-height: 75vh;
        min-width: 0;
    }
}
#review .like-report {
    text-align: right;
    margin-right: 16px;
}
#review-image-viewer .image-viewer-modal-wrapper {
    height: calc(100vh - 100px);
}
#review-image-viewer .image-viewer-modal-wrapper img {
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
.review-side-menu {
    color: #46B476;
    text-indent: 0;
    vertical-align: middle;
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

:root {
    --star-size: 22px;
    --star-color: #cdcdcd;
    --star-background: #fc0;
}
.stars.rating0 { --percent: calc(0 / 5 * 100%);}
.stars.rating05 { --percent: calc(0.5 / 5 * 100%);}
.stars.rating1 { --percent: calc(1 / 5 * 100%);}
.stars.rating15 { --percent: calc(1.5 / 5 * 100%);}
.stars.rating2 { --percent: calc(2 / 5 * 100%);}
.stars.rating25 { --percent: calc(2.5 / 5 * 100%);}
.stars.rating3 { --percent: calc(3 / 5 * 100%);}
.stars.rating35 { --percent: calc(3.5 / 5 * 100%);}
.stars.rating4 { --percent: calc(4 / 5 * 100%);}
.stars.rating45 { --percent: calc(4.5 / 5 * 100%);}
.stars.rating5 { --percent: calc(5 / 5 * 100%);}

.stars {
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; /* make sure ★ appears correctly */
    line-height: 1;
}

.stars::before {
    content: '★★★★★';
    letter-spacing: 0;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media (max-width:767px) {
    .full-width.modal-dialog.modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .full-width.modal-dialog.modal-fullscreen-md-down .modal-content {
        border: none;
        border-radius: 0;
        height: 100%;
    }
    .full-width.modal-dialog.modal-fullscreen-md-down .modal-header {
        border: none;
        border-radius: 0;
    }
    .full-width.modal-dialog.modal-fullscreen-md-down .modal-header .close {
        /*float: left;*/
        display: block;
    }
}

/* 썸네일 */
.thumbnail-content {
    margin: auto;
    padding: 20px;
    max-width: 800px;
}
.thumbnail-content .title {
    display: flex;
    align-items: center;
}
.thumbnail-content .title > p{
    font-weight: bold;
    font-size: 16px;
    margin: auto 0;
}
.thumbnail-content .title .info {
    margin: 5px;
}
.thumbnail-content .thumbnail-wrapper {
    max-height: 400px;
    min-height: 320px;
    display: flex;
    position: relative;
}
.thumbnail-content .thumbnail-wrapper .btn-delete-thumbnail {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px;
    box-shadow: 2px 2px 3px #d1d1d1;
    cursor: pointer;
}
.thumbnail-content .thumbnail-wrapper #thumbnail-img,
.thumbnail-content .thumbnail-wrapper .add-photo {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
    padding: 20px;
    cursor: pointer;
}

.thumbnail-content .thumbnail-wrapper input {
    display: none;
}
#thumbnail-info-modal .modal-dialog {
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(50vh - (490px / 2))
}
#thumbnail-info-modal .modal-header {
    border-bottom: none;
    background-color: #ffffff;
    padding: 0;
}
#thumbnail-info-modal .modal-body {
    padding-top: 0;
}
#thumbnail-info-modal .modal-footer {
    border-top: none;
    padding-top: 0;
}
#thumbnail-info-modal .modal-title {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    float: none;
}
#thumbnail-info-modal .rectangle {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    max-width: 343px;
    height: 440px;
    background-image: url(../images/thumbnail/preview@x4.jpg);
    background-size: 343px;
}
#thumbnail-info-modal .ellipse {
    position: absolute;
    width: 86px;
    height: 86px;
    left: 213px;
    top: 245px;
    border: 4px solid #30B843;
    box-sizing: border-box;
    border-radius: 50px
}

@media (min-width:767px) {
    .thumbnail-content .thumbnail-wrapper #thumbnail-img,
    .thumbnail-content .thumbnail-wrapper .add-photo {
        max-height: 400px;
    }
    .thumbnail-content .thumbnail-wrapper .btn-delete-thumbnail{
        right: 140px;
    }
}

.btn-primary.btn-sikdae-primary {
    background-color: #46B476;
    border-color: #46B476;
}
.btn-primary.btn-sikdae-primary:active,
.btn-primary.btn-sikdae-primary:active:focus,
.btn-primary.btn-sikdae-primary:focus,
.btn-primary.btn-sikdae-primary:hover {
    background-color: #359c64;
    border-color: #359c64;
}

/* @font-face {*/
/*    font-family: 'Material Icons';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: url(node_modules/material-design-icons/iconfont/MaterialIcons-Regular.eot); !* For IE6-8 *!*/
/*    src: local('Material Icons'),*/
/*    local('MaterialIcons-Regular'),*/
/*    url(node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),*/
/*    url(node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),*/
/*    url(node_modules/material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');*/
/*} */

/*.material-icons {*/
/*    font-family: 'Material Icons';*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*    font-size: 24px;  !* Preferred icon size *!*/
/*    display: inline-block;*/
/*    line-height: 1;*/
/*    text-transform: none;*/
/*    letter-spacing: normal;*/
/*    word-wrap: normal;*/
/*    white-space: nowrap;*/
/*    direction: ltr;*/

/*    !* Support for all WebKit browsers. *!*/
/*    -webkit-font-smoothing: antialiased;*/
/*    !* Support for Safari and Chrome. *!*/
/*    text-rendering: optimizeLegibility;*/

/*    !* Support for Firefox. *!*/
/*    -moz-osx-font-smoothing: grayscale;*/

/*    !* Support for IE. *!*/
/*    font-feature-settings: 'liga';*/
/*}*/
