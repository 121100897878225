@import url('./assets/css/sikdang.css');

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Regular'), local('NotoSans-Regular'),
  url(https://cdn.rawgit.com/theeluwin/NotoSansKR-Hestia/master/fonts/eot/NotoSansKR-Regular-Hestia.eot),
  url(https://cdn.rawgit.com/theeluwin/NotoSansKR-Hestia/master/fonts/eot/NotoSansKR-Regular-Hestia.eot?#iefix) format('embedded-opentype'),
  url(./assets/fonts/woff/NotoSansKR-Regular-Hestia.woff) format('woff'),
  url(./assets/fonts/otf/NotoSansKR-Regular-Hestia.otf) format('opentype'),
  url(./assets/fonts/ttf/NotoSansKR-Regular-Hestia.ttf);
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Regular'), local('NotoSans-Regular'),
  url(https://cdn.rawgit.com/theeluwin/NotoSansKR-Hestia/master/fonts/eot/NotoSansKR-Regular-Hestia.eot),
  url(https://cdn.rawgit.com/theeluwin/NotoSansKR-Hestia/master/fonts/eot/NotoSansKR-Regular-Hestia.eot?#iefix) format('embedded-opentype'),
  url(./assets/fonts/woff/NotoSansKR-Regular-Hestia.woff) format('woff'),
  url(./assets/fonts/otf/NotoSansKR-Regular-Hestia.otf) format('opentype'),
  url(./assets/fonts/ttf/NotoSansKR-Regular-Hestia.ttf);
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
  url(https://cdn.rawgit.com/theeluwin/NotoSansKR-Hestia/master/fonts/eot/NotoSansKR-Bold-Hestia.eot),
  url(https://cdn.rawgit.com/theeluwin/NotoSansKR-Hestia/master/fonts/eot/NotoSansKR-Bold-Hestia.eot?#iefix) format('embedded-opentype'),
  url(./assets/fonts/woff/NotoSansKR-Bold-Hestia.woff) format('woff'),
  url(./assets/fonts/otf/NotoSansKR-Bold-Hestia.otf) format('opentype'),
  url(./assets/fonts/ttf/NotoSansKR-Bold-Hestia.ttf);
}

body {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: Pretendard, 'Noto Sans Korean', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0 auto;
}
#pageload {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align:center;
}
#pageload .loadLogo {
  margin-top: 10%;
}
#pageload .loadLogo img {
  width: 150px;
  height: 144px;
}

#pageload .loadText {
  margin-top:30px;
  color: #dcdcdc;
  font-size: 25px;
  height: 50px;
}
#pageload .cssProgress {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

@media (max-width:767px) {
  #pageload .loadLogo {
    margin-top: 40%;
  }
}

